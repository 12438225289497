import { BotsContext } from '../context/BotContext'
import { useContext } from 'react'

export const useBotsContext = () => {
  const context = useContext(BotsContext)

  if (!context) {
    throw Error('useBotsContext must be used inside an BotsContextProvider')
  }

  return context
}