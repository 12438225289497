import { createContext, useReducer } from 'react'

export const BotsContext = createContext()

export const botsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BOTS': 
      return {
        bots: action.payload
      }
    case 'CREATE_BOT':
      return {
        bots: [action.payload, ...state.bots]
      }
    case 'DELETE_BOT':
      return {
        bots: state.bots.filter((w) => w._id !== action.payload._id)
      }
    default:
      return state
  }
}

export const BotsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(botsReducer, {
    bots: null
  })

  return (
    <BotsContext.Provider value={{...state, dispatch}}>
      { children }
    </BotsContext.Provider>
  )
}