'use client'

import React, { ReactNode, useState } from 'react'
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
} from '@chakra-ui/react'
import {
  FiMenu,
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import { ReactText } from 'react'
import { usePublicFetch } from '../hooks/usePublicFetch'
import { useParams } from 'react-router-dom'

import ManageGeneral from '../components/manage/ManageGeneral'
import ManageData from '../components/manage/ManageData'
import ManageRules from '../components/manage/ManageRules'
import ManageBranding from '../components/manage/ManageBranding'
import ManageNotifications from '../components/manage/ManageNotifications'
import ManageWebhook from '../components/manage/ManageWebhook'

const LinkItems = [
  { name: 'General' },
  { name: 'Data' },
  { name: 'Rules' },
  { name: 'Branding' },
  { name: 'Notifications' },
  { name: 'Webhook / API' },
]

const AnalysisLinkItems = [
  { name: 'General' },
  { name: 'Rules' },
  { name: 'Branding'}
]

export default function SimpleSidebar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState('General');
  const {id} = useParams();
  const { data, setData, isPending, error } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/` + id);

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent data = {data} selectedItem={selectedItem} setSelectedItem={setSelectedItem} onClose={onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent data = {data} selectedItem={selectedItem} setSelectedItem={setSelectedItem} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {selectedItem === 'General' && <ManageGeneral data={data} isPending={isPending} error={error} />}
        {selectedItem === 'Data' && <ManageData data={data} isPending={isPending} error={error} />}
        {selectedItem === 'Rules' && <ManageRules data={data} isPending={isPending} error={error} />}
        {selectedItem === 'Branding' && <ManageBranding data={data} setData = {setData} isPending={isPending} error={error} />}
        {selectedItem === 'Notifications' && <ManageNotifications data={data} isPending={isPending} error={error} />}
        {selectedItem === 'Webhook / API' && <ManageWebhook data={data} isPending={isPending} error={error} />}
      </Box>
    </Box>
  );
}
const SidebarContent = ({ selectedItem, setSelectedItem, onClose, data, ...rest }) => {
  return (
    <Box
      bg='gray.100'
      borderRight="2px"
      borderRightColor='gray.300'
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h={{ base: '20', md: '5' }}  alignItems="center" mx="8" justifyContent="space-between">
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      
      {data && data.type === "analysis" && AnalysisLinkItems.map((link) => (
        <NavItem selectedItem = {selectedItem} name = {link.name} key={link.name} onClick={() => setSelectedItem(link.name)}>
          <Text fontWeight = "semibold">{link.name}</Text>
        </NavItem>
      )) }

      {data && data.type !== "analysis" && LinkItems.map((link) => (
        <NavItem selectedItem = {selectedItem} name = {link.name} key={link.name} onClick={() => setSelectedItem(link.name)}>
          <Text fontWeight = "semibold">{link.name}</Text>
        </NavItem>
      )) }

    </Box>
  )
}

const NavItem = ({ selectedItem, icon, name, children, ...rest }) => {
  console.log(selectedItem)
  console.log(name)
  return (
    <Box
      as="a"
      href="#"
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mb="2"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'gray.600',
          color: 'white',
        }}
        bg={selectedItem === name ? 'gray.600' : ''}
        color={selectedItem === name ? 'white' : 'gray.700'}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  )
}

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}>
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        Manage Bot
      </Text>
    </Flex>
  )
}