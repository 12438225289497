import { Flex, Heading, Input, Center, Box, Text, FormControl, FormLabel, Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Divider from "../components/chat/Divider";
import Footer from "../components/chat/Footer";
import Messages from "../components/chat/Messages";
import Home from "../components/chat/Home";
import Suggestions from "../components/chat/Suggestions";
import Header from "../components/chat/Header";
import { useParams } from "react-router-dom";
import { usePublicFetch } from "../hooks/usePublicFetch";
import { v4 as uuidv4 } from 'uuid';
import { useNoAuthFetch } from "../hooks/useNoAuthFetch";

const ChatDemo = ({themeColor, customTypeMessage, userMessageColor, botMessageColor, backgroundColor}) => {
  const {id} = useParams();

  const [messages, setMessages] = useState([{
    from: "computer", text: "Preview how your chatbot will look!"
  }, {
    from: "me", text: "Customize your icon and theme color."
  }]);
  const [history, setHistory] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <Flex w="60vh" h="65vh" justify="center" align="center">
      <Flex w="100%" h="100%" flexDir="column" bgColor = {backgroundColor}>
        <Header widgetColor = {themeColor} visibleName = {"My Chatbot"} badge_visibility = {false} />
        <Messages loading = {loading} messages={messages} bot_id = {id} userMessageColor={userMessageColor} botMessageColor={botMessageColor} backgroundColor={backgroundColor} />
        <Footer
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          loading={loading}
          powered_by = {false}
          demo = {true}
          customTypeMessage={customTypeMessage}
          backgroundColor = {backgroundColor}
        />
      </Flex>
    </Flex>
  );
};

export default ChatDemo;
