import React from "react";
import { Flex, Input, InputGroup, Button, Text, VStack } from "@chakra-ui/react";

const FooterTable = ({
  inputMessage,
  setInputMessage,
  handleGenerateQuery,
  handleUpdateQuery,
  loading,
  powered_by,
  demo,
  customTypeMessage,
  backgroundColor
}) => {
  return (
    <VStack mb="1" bgColor={backgroundColor ? backgroundColor : undefined}>
      <Flex w="100%" mt="5" flexDirection="row">
        <InputGroup mx="5">
          <Input
            maxLength={500}
            placeholder={customTypeMessage ? customTypeMessage : "Type your message..."}
            bgColor="white"
            _focus={{
              border: "1px solid gray",
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                if (demo) {
                  alert('Sending messages is disabled in previewMode.')
                }
                if (!loading) {
                  handleGenerateQuery();  // Assuming Enter should trigger Generate by default
                }
              }
            }}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
        </InputGroup>
        <Button
          px = "10"
          isDisabled={loading || demo}
          onClick={handleGenerateQuery}
          colorScheme="blue"
        >
          Generate New Query
        </Button>
        <Button
          px = "10"
          ml="2"
          mr = "5"
          isDisabled={loading || demo}
          onClick={handleUpdateQuery}
          colorScheme="green"
        >
          Update Query
        </Button>
      </Flex>
      {powered_by ? (
        <Text fontSize="xs">
          Chat by <a href="https://www.botsheets.com" target="_blank" rel="noreferrer"><Text as='u'>Botsheets</Text></a>
        </Text>
      ) : (
        <Text fontSize="xs"> </Text>
      )}
    </VStack>
  );
};

export default FooterTable;