import { Button, Card, FormControl, useDisclosure, FormLabel, Textarea, Select, Checkbox, Icon, CardBody, IconButton, HStack, Spacer, Grid, GridItem, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast} from '@chakra-ui/react'
import DeleteBotButton from '../../DeleteBotButton'
import LoadingBox from '../../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../../hooks/usePublicFetch';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaTrash } from 'react-icons/fa'
import UpgradeModal from '../../UpgradeModal';


const DataRules = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [formData, setFormData] = useState({});
    const [prompt, setPrompt] = useState('');

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSaveClick = () => {


        if (!formData.welcomeMessage) {
            alert('Please fill out all fields');
            return;
        }

        if (formData.leadingQuestion === 'Yes' && !formData.additionalWelcomeMessage) {
            alert('You indicated that you want to ask a leading question, but you did not provide one. Please provide one.');
            return;
        }

        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'rules': formData, 'answer_prompt': prompt})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })
            props.updateBotData(data);
        }
        ).catch(error => {
            console.log(error)
        })
    }

    const handlePromptClick = () => {
        if (user.data._doc.plan === 'free') {
            onOpen();
        }
    }

    useEffect(() => {
        if (data) {
            setFormData(data.rules);
            setPrompt(data.answer_prompt);
        }
    }, [data])


    if (isPending) {
    return <div>Loading Chatbot Info...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box mt = "1" bgColor = "white" w = "100%" borderRadius = "5" p = "3">
      <Heading size='md' fontWeight = "bold" color = "#38a169">Greeting Message</Heading>
      <Text mb = "5" color = "gray.500" fontSize="sm">This is the first message a user will see when they engage with your chatbot.</Text>
        
    <form>

      <Box mt = {4} mb={4}>
        <FormControl>
          <FormLabel>
          </FormLabel>
          <Textarea
            name="welcomeMessage"
            value={formData.welcomeMessage}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          />
        </FormControl>
      </Box>

      {data.type == 'analysis' && <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Heading size='md' fontWeight = "bold" color = "#38a169">Display Explanations</Heading>
          <Text mb = "5" color = "gray.500" fontSize="sm">Displays an explanation of how the results were obtained with each message.</Text>
          </FormLabel>
          <Select
            name="analysisExplanation"
            value={formData.analysisExplanation}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Select>
        </FormControl>
      </Box>}

      {data.type != 'analysis' && <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Heading size='md' fontWeight = "bold" color = "#38a169">Leading Question</Heading>
          <Text mb = "5" color = "gray.500" fontSize="sm">A leading question is a great way to start a conversation and collect data to personalize the conversational experience.</Text>
          </FormLabel>
          <Select
            name="leadingQuestion"
            value={formData.leadingQuestion}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Select>
        </FormControl>
      </Box>}

      {data.type != 'analysis' && formData.leadingQuestion === 'Yes' && (
        <Box mb={4}>
          <FormControl>
            <FormLabel>
            <Text mb = "5" color = "gray.500" fontSize="sm">Enter your leading question. This will immediately follow your greeting message.</Text>
            </FormLabel>
            <Textarea
              name="additionalWelcomeMessage"
              value={formData.additionalWelcomeMessage}
              onChange={handleInputChange}
              backgroundColor = "gray.100"
              borderColor = "gray.300"
            />
          </FormControl>
        </Box>
      )}

      {data.type != 'analysis' && <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Heading size='md' fontWeight = "bold" color = "#38a169">Personality</Heading>
          <Text mb = "5" color = "gray.500" fontSize="sm">This is the voice of your brand. Include &#123;tone&#125; in the Base Prompt to make sure the tone you select here is included.</Text>
          </FormLabel>
          <Select
            name="tone"
            value={formData.tone}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value="lighthearted">Lighthearted</option>
            <option value="Casual">Casual</option>
            <option value="Witty">Witty</option>
            <option value="Sassy">Sassy</option>
            <option value="Energetic">Energetic</option>
            <option value="Professional">Professional</option>
            <option value="Concise">Concise</option>
          </Select>
        </FormControl>
      </Box>}

      {data.type != 'analysis' && <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Heading size='md' fontWeight = "bold" color = "#38a169">OpenAI Model</Heading>
          <Text mb = "5" color = "gray.500" fontSize="sm">Select the model you would like to use. GPT-4o is the newest and quickest model. GPT-4 is slower but can be more accurate.</Text>
          </FormLabel>
          <Select
            name="model"
            value={formData.model}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value="gpt-4o">GPT-4o</option>
            <option value="gpt-4-turbo">GPT-4</option>
            <option value="gpt-4o-mini">GPT-4o Mini</option>
          </Select>
        </FormControl>
      </Box>}

    </form>

        <HStack mt = "5">
            <Spacer />
            <Button colorScheme = "green" onClick = {handleSaveClick}>Save Changes</Button>
        </HStack>

        <UpgradeModal text = {"Upgrade Plans To Edit Prompt"} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
    </Box>
  )
}

export default DataRules