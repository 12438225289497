import { Button, Card, CardBody, CardFooter, CardHeader, Heading, SimpleGrid, Text, Link, Stack, StackDivider, Box, Code, Flex, useToast} from '@chakra-ui/react'
import DeleteBotButton from '../components/DeleteBotButton'
import LoadingBox from './common/LoadingBox';
import { useParams } from 'react-router-dom'
import { usePublicFetch } from '../hooks/usePublicFetch';
import { Helmet } from 'react-helmet';

const ManageEmbed = (props) => {

  const {id} = useParams();
  const toast = useToast();
  const { data, isPending, error } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/` + id);

  var url = ''
  var iframe = ''
  var script = ''

  if (isPending) {
    return (
    <Box p = "10" width = "100%" height = {`calc(100vh - 5rem)`}>
      <LoadingBox />
    </Box>
    )
  }

  if (error) {
    return <div>{ error }</div>
  }

  if (data) {

    url = 'https://chat.botsheets.com/chat/' + id;
    iframe = `<iframe src="${url}" frameborder="0" width="100%" height="100%"></iframe>`
    script = `<script src="https://chat.botsheets.com/chat.js" data-chatbot-id="${id}" defer></script>`

    if (data.cnameDomain) {
      url = 'https://' + data.cnameDomain + '/' + id;
      iframe = `<iframe src="${url}" frameborder="0" width="100%" height="100%"></iframe>`
      script = `<script src="https://` + data.cnameDomain + `/chat.js" data-chatbot-id="${id}" defer></script>`
    }
  
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast({
      title: 'Copied To Clipboard',
      status: 'success',
      duration: 2500,
      isClosable: true,
    })
  };

  console.log(data)

  return (
    <Box p = "10" width = "100%" height = {`calc(100vh - 5rem)`}>
      <Stack divider={<StackDivider />} spacing='4'>
        <Box>
        <Heading size='md' fontWeight = "bold" color = "#38a169">Direct Link</Heading>
        <Text pt = "2" color = "gray.600">Share a direct link to a full screen experience. Ideal for mobile, social bios, and email signatures. <Link href="https://support.botsheets.com/docs/web-chat" isExternal color='#1772cc'>[?]</Link></Text>
          <Box maxW = "800px" mt = "4" bg="gray.600" p="4" borderRadius="md">
            <Flex justifyContent="space-between" alignItems="center" m="2">
              <Code variant = "ghost" color = "white" maxW = "80%" mr = "5" colorScheme="whiteAlpha">{url}</Code>
              <Button
                p = "5"
                size="sm"
                colorScheme="green"
                onClick = {() => handleCopy(url)}
              >
                Copy
              </Button>
            </Flex>
          </Box>
        </Box>
        
        <Box>
        <Heading size='md' fontWeight = "bold" color = "#38a169">Chat Widget</Heading>
        <Text pt = "2" color = "gray.600">Paste this code anywhere below the BODY tag of a webpage for a classic chat widget in the bottom right corner. <Link href="https://support.botsheets.com/docs/web-chat" isExternal color='#1772cc'>[?]</Link></Text>
          <Box maxW = "800px" mt = "4" bg="gray.600" p="4" borderRadius="md">
            <Flex justifyContent="space-between" alignItems="center" m="2">
              <Code variant = "ghost" color = "white" maxW = "80%" mr = "5" colorScheme="whiteAlpha">{script}</Code>
              <Button
                p = "5"
                size="sm"
                colorScheme="green"
                onClick = {() => handleCopy(script)}
              >
                Copy
              </Button>
            </Flex>
          </Box>
        </Box>
        
        <Box>
        <Heading size='md' fontWeight = "bold" color = "#38a169">iFrame Embed</Heading>
        <Text pt = "2" color = "gray.600">Embed an iframe in the middle of content on a webpage. Use as a conversational form. <Link href="https://support.botsheets.com/docs/web-chat" isExternal color='#1772cc'>[?]</Link></Text>
          <Box maxW = "800px" mt = "4" bg="gray.600" p="4" borderRadius="md">
            <Flex justifyContent="space-between" alignItems="center" m="2">
              <Code variant = "ghost" color = "white" maxW = "80%" mr = "5" colorScheme="whiteAlpha">{iframe}</Code>
              <Button
                p = "5"
                size="sm"
                colorScheme="green"
                onClick = {() => handleCopy(iframe)}
              >
                Copy
              </Button>
            </Flex>
          </Box>
        </Box>
      </Stack>
      </Box>
  )
}

export default ManageEmbed