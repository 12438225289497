import { Button, Card, Icon, CardBody, IconButton, HStack, Spacer, Grid, GridItem, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast} from '@chakra-ui/react'
import DeleteBotButton from '../../DeleteBotButton'
import LoadingBox from '../../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../../hooks/usePublicFetch';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaTrash } from 'react-icons/fa'


const DataKnowledge = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [questions, setQuestions] = useState([]);

    const addQuestion = () => {
        if (questions.length < 20) {
          // Create a new question object and add it to the state
          const newQuestion = { data_point: '', description: '', userInput: '' };
          setQuestions([...questions, newQuestion]);
        } else {
          // Handle the case when the maximum number of questions is reached
          alert('You can only add up to 20 questions.');
        }
      };

    const removeQuestion = (index) => {
        // Remove the question at the specified index from the state
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };


    const handleSaveClick = () => {

        //iterate through questions and check if any of the data_point or userInput fields are empty
        for (var i = 0; i < questions.length; i++) {
            if (questions[i].data_point === '' || questions[i].userInput === '') {
                alert('Please fill out all fields before saving.');
                return;
            }
        }
        

        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'questions': questions})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })
            props.updateBotData(data);
        }
        ).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (data) {
            setQuestions(data.questions);
        }
    }, [data])


    if (isPending) {
    return <div>Loading Chatbot Info...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box mt = "1" bgColor = "white" w = "100%" borderRadius = "5" p = "3">
        <Heading size='md' fontWeight = "bold" color = "#38a169">Share Data</Heading>
        <Text mb = "5" color = "gray.500" fontSize="sm">Share key business data and AI can generate responses. Use this simple data structure so AI has basic knowledge such as your business name, address, or hours of operation.</Text>        
        <Flex pl = "1" width = "100%" flexWrap = "wrap">
            <Box width="30%" padding = "5px" pl = "0px">
                <Text fontSize = "sm" fontWeight = "bold">Key Business Data</Text>
            </Box>

            <Box width="60%" padding = "5px">
                <Text fontSize = "sm" fontWeight = "bold">Describe The Data</Text>
            </Box>

        </Flex>

        {questions.map((datapoint, index) => (
        <Flex key={index} width="100%" flexWrap="wrap">
            <Box width="30%" padding = "5px" pl = "0px">
                <Input
                placeholder = "Example: Hours of Operation"
                maxLength={256}
                fontSize = "sm"
                backgroundColor = "gray.100"
                borderColor = "gray.300"
                value={datapoint.data_point}
                isTruncated
                onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[index].data_point = e.target.value;
                    setQuestions(updatedQuestions);
                }}
                />
            </Box>
            <Box width="60%" padding="5px">
                <Input
                placeholder = "9am - 5pm Monday to Friday"
                maxLength={256}
                fontSize = "sm"
                backgroundColor = "gray.100"
                borderColor = "gray.300"
                value={datapoint.userInput}
                isTruncated
                onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[index].userInput = e.target.value;
                    setQuestions(updatedQuestions);
                }}
                />
            </Box>
            <Box width="10%" padding="5px" textAlign="center">
                <IconButton
                aria-label="Delete"
                icon={<FaTrash />}
                variant="ghost"
                colorScheme="red"
                onClick={() => removeQuestion(index)}
                />
            </Box>
        </Flex>
            ))}
        
        <HStack mt = "5">
            <Button mr = "3" colorScheme = "green" variant = "outline" onClick = {addQuestion}>+ Add</Button>
            <Spacer />
            <Button colorScheme = "green" onClick = {handleSaveClick}>Save Changes</Button>
        </HStack>

    </Box>
  )
}

export default DataKnowledge