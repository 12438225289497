import { Flex, Heading, Input, Center, Box, Text, FormControl, FormLabel, Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Divider from "../components/chat/Divider";
import Footer from "../components/chat/Footer";
import MessagesAnalysis from "../components/chat/MessagesAnalysis";
import Home from "../components/chat/Home";
import Suggestions from "../components/chat/Suggestions";
import Header from "../components/chat/Header";
import { useParams } from "react-router-dom";
import { usePublicFetch } from "../hooks/usePublicFetch";
import { v4 as uuidv4 } from 'uuid';
import FooterTest from "./chat/FooterTest";

const ManageAnalysisTest = () => {
  const {id} = useParams();

  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState(null);
  const [uuid, setUuid] = useState(''); //uuid for the user
  const { data, error, isPending } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/` + id);

  const [password, setPassword] = useState('');
  const [hideScreen, setHideScreen] = useState(true);

  //create a uuid on page load for the user
  useEffect(() => {
    setUuid(uuidv4())
  }, [])

  if (isPending) {
    return <div></div>
  }

  if (error) {
    return <div>{ error }</div>
  }

  if (data && data.active === false) {
    return (
      <Heading>This chatbot is currently unavailable. If you are the owner, check your dashboard.</Heading>
      )
  }

  if (data && data.rules.welcomeMessage && messages.length === 0) {
    setMessages([{ from: "computer", text: data.rules.welcomeMessage }]);
    setHistory([{ role: 'assistant', content: data.rules.welcomeMessage }]);


    if (data.rules.leadingQuestion === "Yes" && data.rules.additionalWelcomeMessage) {
        setMessages((old) => [...old, { from: "computer", text: data.rules.additionalWelcomeMessage }]);
        setHistory((old) => [...old, { role: 'assistant', content: data.rules.additionalWelcomeMessage }]);
    }
  }

  const handleSendMessage = async () => {
    
    if (!inputMessage.trim().length) {
      //console.log('empty msg');
      return;
    }

    setLoading(true)
    setMessages((old) => [...old, { from: "me", text: inputMessage }]);
    setHistory((old) => [...old, { role: 'user', content: inputMessage }])
    setInputMessage("");

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/analysis/` + id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: inputMessage,
          chatID: uuid,
        }),
      });
      const data = await response.json();
      console.log('data', data);

      console.log(data.result.type)

      const explanation = data.result.explanation; // Replace this with actual explanation logic

      if (data.result.type === 'string') {
        setMessages((old) => [...old, { from: "computer", text: data.result.data, explanation: data.result.explanation }]);
      }

      else if (data.result.type === 'dataframe') {
        setMessages((old) => [...old, { from: "dataframe", text: data.result.data, explanation: data.result.explanation }]);
      }

      else if (data.result.type === 'image') {
        setMessages((old) => [...old, { from: "image", text: data.result.data, explanation: data.result.explanation }]);
      }


      if (explanation != "" && explanation != null && data.result.data != "I had an issue querying your data. Try rephrasing your question or request.") {
        setMessages((oldMessages) => [
          ...oldMessages,
          { from: "computer", text: explanation }
        ]);
      }


      /* setSuggestions([['Need more help?'], ["Ask another question"]]); */
      setLoading(false);
      

    } catch (error) {
      setLoading(false);
      setMessages((old) => [...old, { from: "computer", text: "Hmm, I think our wires got crossed. Can you say that again?" }]);
    }
  };

  console.log(messages)

  const handleReset = () => {
    setMessages([]);
    setHistory([]);
    setInputMessage("");
    setSuggestions(null);
    setUuid(uuidv4());

    if (data && data.rules.welcomeMessage && messages.length === 0) {
        setMessages([{ from: "computer", text: data.rules.welcomeMessage }]);
        setHistory([{ role: 'assistant', content: data.rules.welcomeMessage }]);
    
    
        if (data.rules.additionalWelcomeMessage) {
            setMessages((old) => [...old, { from: "computer", text: data.rules.additionalWelcomeMessage }]);
            setHistory((old) => [...old, { role: 'assistant', content: data.rules.additionalWelcomeMessage }]);
        }
      }
  }

  console.log(messages)

  const handleExplainResult = async () => {
    if (messages.length === 0) return;
  
    const lastMessage = messages[messages.length - 1];
  
    // Call API or logic to get the explanation for the last message
    // For this example, I'm directly using `lastMessage.text` as the explanation
    const explanation = lastMessage.explanation; // Replace this with actual explanation logic
  
    // Append new message with explanation
    setMessages((oldMessages) => [
      ...oldMessages,
      { from: "computer", text: explanation }
    ]);
  };


  return (
    <Flex w="100%" height = {`calc(100vh - 5rem)`} justify="center" align="center">
      <Flex w="100%" h="100%" flexDir="column">
        <Divider />
        <MessagesAnalysis loading = {loading} messages={messages} bot_id = {id} onExplainResult={handleExplainResult}/>
        <Divider />
        <FooterTest
          handleReset={handleReset}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSendMessage={handleSendMessage}
          loading={loading}
          powered_by = {data.powered_by}
          customTypeMessage= {data.customTypeMessage}
        />
      </Flex>
    </Flex>
  );
};

export default ManageAnalysisTest;
