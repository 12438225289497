import React, { useState } from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';

const AddDatapointModal = ({ isOpen, onClose, addNewDatapoint }) => {

    const [datapointName, setDatapointName] = useState('');
    const [description, setDescription] = useState('');
    const [exampleQuestion, setExampleQuestion] = useState('');

    const handleNewDatapoint = () => {
        if (!datapointName || !description) {
            alert('Please fill out all fields.');
            return;
        }

        // check that datapointName contains no spaces or special characters
        if (!/^[a-zA-Z0-9_]+$/.test(datapointName)) {
            return alert('Datapoint name can only contain letters, numbers, and underscores. It should contain no spaces, we recommend using underscores instead to separate words.');
        }

        addNewDatapoint(datapointName, description, exampleQuestion);

        setDatapointName('');
        setDescription('');
        setExampleQuestion('');

        onClose();
    }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add a Custom Data Point</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
          <FormControl isRequired>
            <FormLabel>Data Point Name</FormLabel>
            <Input 
            placeholder='e.g. email_address'
            value={datapointName}
            onChange={(e) => setDatapointName(e.target.value)}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
            />
            
            <FormLabel mt = "3" >Briefly describe the purpose of the data point</FormLabel>
            <Input 
            placeholder='e.g. An email address to follow up with the customer.'
            value={description}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
            onChange={(e) => setDescription(e.target.value)} />
          </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick = {handleNewDatapoint} colorScheme = "green">Add Custom Data Point</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddDatapointModal;