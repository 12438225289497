import { Button, Link, Divider, Card, FormControl, Textarea, FormLabel, Icon, useDisclosure, Spacer, Switch, CardBody, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast } from '@chakra-ui/react'
import DeleteBotButton from '../DeleteBotButton'
import LoadingBox from '../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../hooks/usePublicFetch';
import { useAuthContext } from '../../hooks/useAuthContext';
import { CompactPicker } from 'react-color';
import { IoChatboxSharp } from 'react-icons/io5';
import UpgradeModal from '../UpgradeModal';
import Chat from '../../pages/Chat';
import ChatDemo from '../../pages/ChatDemo';

const ManageBranding = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;
    const setData = props.setData;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();
    const [show, setShow] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);
    const [messageBubbles, setMessageBubbles] = useState(false);
    const [privacyPopup, setPrivacyPopup] = useState(false);
    const [privacyMessage, setPrivacyMessage] = useState('Welcome to our innovative AI chatbot! By engaging in conversation, you consent to the monitoring, recording, and storage of your chats by us and select partners as specified in our Privacy Policy and Terms of Service');

    const [iconColor, setIconColor] = useState('');
    const [widgetColor, setWidgetColor] = useState('');
    const [customTypeMessage, setCustomTypeMessage] = useState('');
    const [userMessageColor, setUserMessageColor] = useState('#65748a');
    const [botMessageColor, setBotMessageColor] = useState('#c4cfdb');
    const [backgroundColor, setBackgroundColor] = useState('#ffffff');

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()

    const handleSaveClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: { 'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'iconColor': iconColor, 'widgetColor': widgetColor, 'powered_by': show, 'customTypeMessage': customTypeMessage, 'rules.messageBubbles': messageBubbles, 'rules.userMessageColor': userMessageColor, 'rules.botMessageColor': botMessageColor, 'rules.backgroundColor': backgroundColor, 'rules.privacyPopup': privacyPopup, 'rules.privacyMessage': privacyMessage })
        })

            .then(
                setData({ ...data, powered_by: show, iconColor: iconColor, widgetColor: widgetColor }),
                toast({
                    title: `Successfully Saved. Refresh page to see changes.`,
                    isClosable: true,
                    status: "success",
                    duration: 1800,
                })
            )
    };


    const shadeColor = (color, percent) => {

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    const handleBooleanChange = (e) => {
        if (user.data._doc.plan === 'free' || user.data._doc.plan === 'lite' || user.data._doc.plan === 'ASTier1' || user.data._doc.plan === 'ASTier2') {
            onOpen();
        } else {
            setShow(e.target.checked);
        }
    }

    const handleMessageBubblesChange = (e) => {
        if (user.data._doc.plan === 'free' || user.data._doc.plan === 'lite' || user.data._doc.plan === 'ASTier1' || user.data._doc.plan === 'ASTier2') {
            onOpen();
        } else {
            setMessageBubbles(e.target.checked);
        }
    }

    const handlePrivacyPopupChange = (e) => {
        if (user.data._doc.plan === 'free' || user.data._doc.plan === 'lite' || user.data._doc.plan === 'ASTier1' || user.data._doc.plan === 'ASTier2') {
            onOpen();
        } else {
            setPrivacyPopup(e.target.checked);
        }
    }

    useEffect(() => {
        if (data) {
            setIconColor(data.iconColor);
            setWidgetColor(data.widgetColor)
            setShow(data.powered_by)
            setCustomTypeMessage(data.customTypeMessage)
            if (data.rules.messageBubbles) {
                setMessageBubbles(data.rules.messageBubbles)
            }
            if (data.rules.userMessageColor) {
                setUserMessageColor(data.rules.userMessageColor)
            }
            if (data.rules.botMessageColor) {
                setBotMessageColor(data.rules.botMessageColor)
            }
            if (data.rules.backgroundColor) {
                setBackgroundColor(data.rules.backgroundColor)
            }
            if (data.rules.privacyPopup) {
                setPrivacyPopup(data.rules.privacyPopup)
            }
            if (data.rules.privacyMessage) {
                setPrivacyMessage(data.rules.privacyMessage)
            }
        }
    }, [])

    const toggleChat = () => {
        setChatVisible(!chatVisible);
        console.log(chatVisible)
    };



    if (isPending) {
        return <div>Loading Chatbot Info...</div>
    }

    if (error) {
        return <div>{error}</div>
    }


    return (
        <Box p="10" pt="5" width="100%" height={`100%`}>

            <Flex mt="0" justifyContent="space-between">
                <Box>
                    <Text fontSize="lg" fontWeight="bold" >Botsheets Branding</Text>
                    <Text fontSize="sm">Include or hide a "Chat by Botsheets" link in the footer of your chats.</Text>
                    <Switch
                        isChecked={show}
                        onChange={handleBooleanChange} />
                </Box>
            </Flex>

            <Flex mt="5" justifyContent="space-between">
                <Box>
                    <Text fontSize="lg" fontWeight="bold" >Message Bubbles</Text>
                    <Text fontSize="sm">Display your greeting message and leading question in floating bubbles above your chat widget when the page loads (Preview unavailable here).</Text>
                    <Switch
                        isChecked={messageBubbles}
                        onChange={handleMessageBubblesChange} />
                </Box>
            </Flex>

            <Flex mt="5" justifyContent="space-between">
                <Box>
                    <Text fontSize="lg" fontWeight="bold" >Privacy Popup</Text>
                    <Text fontSize="sm">Display a custom message that requires users to consent to you collecting and storing data before they start chatting (Preview unavailable here).</Text>
                    <Switch
                        isChecked={privacyPopup}
                        onChange={handlePrivacyPopupChange} />
                </Box>
            </Flex>

            {privacyPopup && (
                <Flex mt="5" justifyContent="space-between">
                    <Box w="100%">
                        <Text fontSize="lg" fontWeight="bold" >Your Privacy Message</Text>
                        <Text fontSize="sm">Edit the privacy message. To display URLs, use Markdown format: [link text](https://example.com)</Text>
                        <Input
                            mt="2"
                            bgColor="white"
                            h="50px"
                            value={privacyMessage}
                            onChange={(e) => setPrivacyMessage(e.target.value)}
                        />
                    </Box>
                </Flex>
            )}


            <Text mt="5" mb="2" fontSize="lg" fontWeight="bold" >Input Placeholder Message</Text>
            <Text fontSize="sm">Edit the call-to-action message in the message input box. Use any language. Your bot will respond in the user's language.</Text>
            <Box mt={4} mb={2}>
                <FormControl>
                    <FormLabel>
                    </FormLabel>
                    <Input
                        mr={2}
                        bgColor="white"
                        h="50px"
                        value={customTypeMessage}
                        onChange={(e) => setCustomTypeMessage(e.target.value)}
                    />
                </FormControl>
            </Box>

            <Flex mt="10" justifyContent="space-between">
                <Box>
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Chat Header Color</Text>
                    <CompactPicker
                        color={widgetColor}
                        onChangeComplete={(color) => {
                            setWidgetColor(color.hex);
                        }}
                    />
                    <Text mb="2" fontSize="sm" >
                        <Link href="/colorpicker.html" isExternal color='#1772cc'>Get custom color code</Link>
                    </Text>
                </Box>

                <Box ml="10">
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Selected Color</Text>
                    <Box borderRadius="10" h="50px" w="50px" bgColor={widgetColor}></Box>
                </Box>

                <Spacer />

            </Flex>

            <Flex mt="10" justifyContent="space-between">
                <Box>
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Chat Background Color</Text>
                    <CompactPicker
                        color={backgroundColor}
                        onChangeComplete={(color) => {
                            setBackgroundColor(color.hex);
                        }}
                    />
                    <Text mb="2" fontSize="sm" >
                        <Link href="/colorpicker.html" isExternal color='#1772cc'>Get custom color code</Link>
                    </Text>
                </Box>

                <Box ml="10">
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Selected Color</Text>
                    <Box borderRadius="10" h="50px" w="50px" bgColor={backgroundColor}></Box>
                </Box>

                <Spacer />

            </Flex>

            <Flex mt="10" justifyContent="space-between">
                <Box>
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Chat Widget Icon Color</Text>
                    <CompactPicker
                        color={iconColor}
                        onChangeComplete={(color) => {
                            setIconColor(color.hex);
                        }}
                    />
                    <Text mb="2" fontSize="sm" >
                        <Link href="/colorpicker.html" isExternal color='#1772cc'>Get custom color code</Link>
                    </Text>
                </Box>

                <Box ml="10">
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Selected Color</Text>
                    <Box borderRadius="10" h="50px" w="50px" bgColor={iconColor}></Box>
                </Box>

                <Spacer />

            </Flex>



            <Flex mt="10" justifyContent="space-between">
                <Box>
                    <Text mb="2" fontSize="lg" fontWeight="bold" >User Message Bubble Color</Text>
                    <CompactPicker
                        color={userMessageColor}
                        onChangeComplete={(color) => {
                            setUserMessageColor(color.hex);
                        }}
                    />
                    <Text mb="2" fontSize="sm" >
                        <Link href="/colorpicker.html" isExternal color='#1772cc'>Get custom color code</Link>
                    </Text>
                </Box>

                <Box ml="10">
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Selected Color</Text>
                    <Box borderRadius="10" h="50px" w="50px" bgColor={userMessageColor}></Box>
                </Box>

                <Spacer />

            </Flex>


            <Flex mt="10" justifyContent="space-between">
                <Box>
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Bot Message Bubble Color</Text>
                    <CompactPicker
                        color={botMessageColor}
                        onChangeComplete={(color) => {
                            setBotMessageColor(color.hex);
                        }}
                    />
                    <Text mb="2" fontSize="sm" >
                        <Link href="/colorpicker.html" isExternal color='#1772cc'>Get custom color code</Link>
                    </Text>
                </Box>

                <Box ml="10">
                    <Text mb="2" fontSize="lg" fontWeight="bold" >Selected Color</Text>
                    <Box borderRadius="10" h="50px" w="50px" bgColor={botMessageColor}></Box>
                </Box>

                <Spacer />

            </Flex>



            <Button mt="10" h="50px" w="200px" colorScheme="green" onClick={() => handleSaveClick({ 'iconColor': iconColor })}>
                Save Settings
            </Button>

            <Box position="relative">
                <Box
                    position="fixed"
                    bottom="125px"
                    right="2rem"
                    bg="white"
                    borderRadius="5px"
                    boxShadow="md"
                    alignItems="center"
                    justifyContent="center"
                    display={chatVisible ? 'block' : 'none'}
                >
                    <ChatDemo themeColor={widgetColor} customTypeMessage={customTypeMessage} userMessageColor={userMessageColor} botMessageColor={botMessageColor} backgroundColor={backgroundColor} />
                </Box>

                <Box
                    position="fixed"
                    bottom="2rem"
                    right="2rem"
                    p="1.5rem"
                    bg={iconColor}
                    borderRadius="50%"
                    boxShadow="md"
                    cursor="pointer"
                    _hover={{ bg: shadeColor(iconColor, -10) }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={toggleChat}
                >
                    <Icon as={IoChatboxSharp} w={8} h={8} color="white" />
                </Box>
            </Box>

            <UpgradeModal text={"Upgrade your plan to remove Botsheets branding from your chatbots"} user={user} userPlan={user.data._doc.plan} isOpen={isOpen} onClose={onCloseModal} />
        </Box>

    )
}

export default ManageBranding
