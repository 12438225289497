import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    Box,
    Button,
    Divider,
  } from '@chakra-ui/react'
  
import { useState } from 'react';

import './styling.css';

import Step1 from './Step1'
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

const MainStepper = ({handleUpdateTitle}) => {

    const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 0
    })

    const steps = [
      { title: 'Add a Goal', description: `Enter a goal or purpose for the chatbot`, component: <Step1 handleUpdateTitle = {handleUpdateTitle} goToPrevious = {goToPrevious} goToNext={goToNext} />},
      { title: 'Add Your Data', description: `Train your chatbot to generate responses.`, component: <Step2 goToPrevious = {goToPrevious} goToNext={goToNext} setActiveStep={setActiveStep}/>},
      { title: 'Collect Data', description: 'Train your chatbot to collect and data from users.', component: <Step4 goToPrevious = {goToPrevious} goToNext={goToNext} setActiveStep={setActiveStep} /> },
      { title: 'Set Rules', description: 'Set rules of engagement for your chatbot.', component: <Step5 goToPrevious = {goToPrevious} goToNext={goToNext} /> },
      { title: 'Test Your Bot', description: 'See how your chatbot will engage with your audience.', component: <Step6 goToPrevious = {goToPrevious} goToNext={goToNext} /> },
    ]
    
    handleUpdateTitle();
    
    return (
    <div>
    <Stepper mb = "5" mt = "5" colorScheme = "green" index={activeStep}>
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator>
            <StepStatus

              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box ml = "1" maxW = "75%" flexShrink='0'>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription >{step.description}</StepDescription>
          </Box>

        </Step>
      ))}
    </Stepper>
    <Box borderRadius = "5" backgroundColor = "white" p = "5" box-shadow = "3px 3px 5px 0px rgba(0,0,0,0.5)" border = "1px solid #ccc">
      {steps[activeStep].component}
    </Box>
    </div>
    )

}

export default MainStepper