import React, { useState } from 'react';
import {
  Box,
  Link,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  Text,
  ModalFooter
} from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';

const URLImportModal = ({ data, updateBotData, storedBot, setStoredBot, addDataSource, changeDatasourceStatus, isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuthContext();
  const {id} = useParams();
  const botData = data;

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {

    setLoading(true);

    if (!inputValue) {
      alert('Please provide a URL.');
      setLoading(false);
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/create/upload/${id}/url`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({url: inputValue})
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorData => {
          throw new Error(JSON.stringify(errorData));
        });
      }
      return response.json()
    }).then(data => {

      const storedDoc = {
        name: inputValue,
        type: 'URL',
        pdfUUid: data.pdfUuid,
        charCount: data.charCount,
        url: data.url,
      }

      const updatedBot = {...botData, files: [...botData.files, storedDoc]}

      updateBotData(updatedBot)
      onClose();
      setInputValue(null);
      setLoading(false);

      onClose();


    }).catch(error => {
      try {
        const errorObj = JSON.parse(error.message);

        if (errorObj.error === 'Character limit exceeded') {
          alert('This website exceeds your character limit. Please upgrade your plan to process larger websites.');
        } 
      } catch (e) {
        alert('Invalid URL.');
      }
        setLoading(false);
    })

    // Handle the button click action here
    console.log('Button clicked with input value:', inputValue);
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect a Webpage</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <FormControl>
            <Input
              placeholder="https://"
              value={inputValue}
              onChange={handleInputChange}
              mb={4} // Add margin-bottom to separate input from the button
              backgroundColor = "gray.100"
              borderColor = "gray.300"
            />
              <Text mb = "5" fontSize="sm">Add one link at a time. Botsheets will <Link href="https://support.botsheets.com/docs/connected-data" isExternal color='#1772cc'>scrape your webpage</Link> and add it to your knowledge base for this chatbot. You only need to connect a webpage once. We keep AI in sync with changes to your website content.</Text>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
            <Button isLoading = {loading} colorScheme='green' mr={3} onClick={handleButtonClick}>
              Scrape URL
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default URLImportModal;