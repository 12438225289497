import {
  Box, Flex, Heading, Button, Text, VStack, Divider, useToast, Link, List, ListItem,
  ListIcon, useColorModeValue, IconButton, Icon, useDisclosure
} from '@chakra-ui/react';
import { MdChat } from 'react-icons/md';
import LoadingBox from './common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../hooks/usePublicFetch';
import { formatDistanceToNow, format } from 'date-fns';
import UpgradeModal from './UpgradeModal';
import { useAuthContext } from '../hooks/useAuthContext';

const ManageSheet = (props) => {

    const {id} = useParams();
    const { user } = useAuthContext();
    const toast = useToast();
    const { data, isPending, error } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/logs/` + id);

    const [selectedChatlog, setSelectedChatlog] = useState(null);

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()

    let url = '';

    if (data) {
      console.log(data)
      url = 'https://docs.google.com/spreadsheets/d/' + data.sheet_id.sheets_id + '/edit#gid=0';

    }

    useEffect(() => {
      if (data && data.chatlogs.length > 0) {
        setSelectedChatlog(data.chatlogs[0]);
      }
    }, [data]);

    if (isPending) {
      return (
      <Box p = "10" width = "100%" height = {`calc(100vh - 5rem)`}>
        <LoadingBox />
      </Box>
      )
    }

    if (error) {
    return <div>{ error }</div>
    }

    const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast({
        title: 'Text Copied',
        status: 'success',
        duration: 2500,
        isClosable: true,
    })
    };

    const redirectToDifferentDomain = () => {
        window.location.href = url;
    };

    const handleChatLogSelect = (log) => {
      setSelectedChatlog(log);
    };
    
    console.log(selectedChatlog)

  return (
    <Flex p="10" width="100%" height={`calc(100vh - 5rem)`} direction={{ base: 'column', md: 'row' }}>
      <VStack pb = "3" width={{ base: '100%', md: '30%' }} pr={4} spacing={4} align='flex-start'>
        {data.sheet_id.sheets_id && (
        <Box w = '100%' p = "3" borderWidth = "1px" bgColor = "white" borderRadius = "md">
        <Text fontSize = "md" pb = "3" fontWeight = "bold" color = "gray.600" >View Data Collected</Text>
          <Text fontSize = "sm" pb = "3" color = "gray.600" ><Link href="https://support.botsheets.com/docs/collecting-data" isExternal color='#1772cc'>Data collected</Link> is written to a new Google Sheet. You can also retrieve data by <Link href="https://support.botsheets.com/docs/email" isExternal color='#1772cc'>email, API, or Webhook</Link>. </Text>
          <Link _hover={{ textDecoration: 'none' }} href={url} isExternal>
            <Button w = "100%" colorScheme="green">Open Google Sheet</Button>
          </Link>
        </Box>)}
          <Box  maxH={`calc(100vh - 10rem)`} overflowY="auto" w = '100%' p = "3" borderWidth = "1px" bgColor = "white" borderRadius = "md">
            <Text fontSize = "md" fontWeight = "bold" pb = "3" color = "gray.600">View Chat Transcripts</Text>
            <Text fontSize = "sm" pb = "3" color = "gray.600">Your Botsheets plan determines access to your transcript history.</Text>  
          {data && data.chatlogs.length > 0 ? (
            <List spacing={3}>
              {data.chatlogs.map((log, index) => (
                <Button p = "3" w = "100%" colorScheme = "green" variant={selectedChatlog && selectedChatlog.uuid === log.uuid ? "solid" : "outline"} key={log.id} onClick={() => {
                  if (log.valid) {
                    // Change selectedUuid if log is valid
                    handleChatLogSelect(log);
                  } else {
                    // Open a modal if log is not valid
                    onOpen(); // Make sure to define the openModal function
                  }
                }}>
                  <ListIcon as={MdChat} color={selectedChatlog && selectedChatlog.uuid === log.uuid ? "white" : "green.500"} />
                  {/* {`Conversation ${index + 1}: `} */}
                  {formatDistanceToNow(new Date(log.createdAt), { addSuffix: true })}
                </Button>
              ))}
            </List>
          ) : (
            <Flex direction="column" align="center">
              <Icon as={MdChat} w={10} h={10} mb={2} />
              <Text textAlign = "center">None yet!</Text>
            </Flex>
          )}
        </Box>
      </VStack>
      <Box flex={1} bg={useColorModeValue('gray.100', 'gray.700')} >
        {selectedChatlog && selectedChatlog.messages ? (
          user.plan === 'free' ? (
            <Box filter='blur(8px)' bg='gray.300' h='100%' w='100%' />
          ) : (
            <VStack
      maxH={`calc(100vh - 10rem)`}
      overflowY="auto"
      borderWidth="2px"
      bgColor="white"
      borderRadius="10"
      p="4"
      align='flex-start'
    >
      {/* Display transcript info */}
      <Text fontWeight="bold" color="gray.600" fontSize="md">
        Chat Started: {format(new Date(selectedChatlog.createdAt), 'yyyy-MM-dd HH:mm')} UTC
      </Text>
      <Text fontWeight="bold" color="green.500" fontSize="sm">
        Conversation ID: {selectedChatlog.uuid}
      </Text>

      {/* Divider line */}
      <Divider width="100%" borderColor="gray.600" my="4" />

      {/* Display messages */}
      {selectedChatlog.messages.map((msg, index) => (
        <Text
          key={index}
          color="gray.600"
          bgColor={msg.assistant ? 'white' : 'gray.100'} // Set background color based on message sender
          p="2"
          borderRadius="6"
          wordBreak="break-all"
        >
          <b>{msg.assistant ? 'Assistant:' : 'User:'}</b> {msg.assistant || msg.user}
        </Text>
      ))}
    </VStack>
          )
        ) : (
          <Text>Select a transcript and it will appear here.</Text>
        )}
      </Box>

      <UpgradeModal text = {"Upgrade your plan and extend chat history for up to 30 days."} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
    </Flex>
  )
}

export default ManageSheet