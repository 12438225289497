import { Button, Link, Card, Icon, CardBody, IconButton, HStack, Spacer, Switch, Grid, GridItem, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast} from '@chakra-ui/react'
import DeleteBotButton from '../../DeleteBotButton'
import LoadingBox from '../../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../../hooks/usePublicFetch';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaTrash, FaArrowUp, FaArrowDown } from 'react-icons/fa'


const DataDatapoints = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [datapoints, setDatapoints] = useState([]);

    const addDatapoint = () => {
        // Create a new datapoint object and add it to the state
        const newDatapoint = { id: datapoints.length + 1, description: '', example_question: '', extracted_data: [''], required: false };
        setDatapoints([...datapoints, newDatapoint]);
      };

    const removeDatapoint = (index) => {
        // Remove the datapoint at the specified index from the state
        const updatedDatapoints = [...datapoints];
        updatedDatapoints.splice(index, 1);
        setDatapoints(updatedDatapoints);
    };


    const handleSaveClick = () => {

        console.log(datapoints)

        //iterate through datapoints and check if any of the data_point or userInput fields are empty
        for (var i = 0; i < datapoints.length; i++) {
            if (datapoints[i].description === '' || datapoints[i].extracted_data[0] === '') {
                alert('Please fill out all fields before saving.');
                return;
            }
        }

        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'datapoints': datapoints})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })

            props.updateBotData(data);
        }
        ).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (data) {
            setDatapoints(data.datapoints);
        }
    }, [data])

    if (isPending) {
    return <div>Loading Data Management Tools...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box mt = "1" bgColor = "white" w = "100%" borderRadius = "5" p = "3">
        <Heading size='md' fontWeight = "bold" color = "#38a169">Collect Data</Heading>
        <Text mb = "5" color = "gray.500" fontSize="sm">Tell AI what <Link href="https://support.botsheets.com/docs/collecting-data" isExternal color='#1772cc'>data to collect</Link> in a chat session and your chatbot will generate questions to collect it. For data points with more than one word, use underscore instead of spaces. If you indicate data as required your chatbot will be persistent about collecting it.</Text>
        <Flex pl = "1" width = "100%" flexWrap = "wrap">
            <Box width="30%" padding = "5px" pl = "0px">
                <Text fontSize = "sm" fontWeight = "bold">Data Point</Text>
            </Box>

            <Box width="40%" padding = "5px">
                <Text fontSize = "sm" fontWeight = "bold">Data Description</Text>
            </Box>

            <Box width="10%" padding = "5px">
                <Text fontSize = "sm" fontWeight = "bold">Require</Text>
            </Box>

            <Box width="10%" padding = "5px">
                <Text fontSize = "sm" fontWeight = "bold">Priority</Text>
            </Box>

        </Flex>
        
        {datapoints.map((datapoint, index) => (
    <Flex key={index} width="100%" flexWrap="wrap">
        <Box width="30%" padding="5px">
            <Input
                placeholder="example: email_address"
                maxLength={256}
                fontSize="sm"
                backgroundColor="gray.100"
                borderColor="gray.300"
                value={datapoint.extracted_data}
                isTruncated
                onChange={(e) => {
                    // check that the e.target.value does not contain any special characters besides letters, numbers, and underscores and spaces
                    if (e.target.value && !/^[a-zA-Z0-9_]+$/.test(e.target.value)) {
                        return alert('Data point name can only contain letters, numbers, and underscores. It should contain no spaces, we recommend using underscores instead to separate words.');
                    }

                    const updatedDatapoints = [...datapoints];
                    updatedDatapoints[index].extracted_data[0] = e.target.value;
                    setDatapoints(updatedDatapoints);
                }}
            />
        </Box>
        <Box width="40%" padding="5px">
            <Input
                placeholder="Customer's email for marketing purposes."
                maxLength={256}
                fontSize="sm"
                backgroundColor="gray.100"
                borderColor="gray.300"
                value={datapoint.description}
                isTruncated
                onChange={(e) => {
                    const updatedDatapoints = [...datapoints];
                    updatedDatapoints[index].description = e.target.value;
                    setDatapoints(updatedDatapoints);
                }}
            />
        </Box>
        <Box width="10%" padding="5px" pt="10px">
            <Switch isChecked={datapoint.required} onChange={() => {
                const updatedDatapoints = [...datapoints];
                updatedDatapoints[index].required = !updatedDatapoints[index].required;
                setDatapoints(updatedDatapoints);
            }} />
        </Box>
        <Box width="10%" padding="5px" textAlign="center">
            <Flex flexDir = "row">
                <IconButton
                    aria-label="Move Up"
                    icon={<FaArrowUp />}
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                        if (index > 0) {
                            const updatedDatapoints = [...datapoints];
                            const temp = updatedDatapoints[index];
                            updatedDatapoints[index] = updatedDatapoints[index - 1];
                            updatedDatapoints[index - 1] = temp;
                            setDatapoints(updatedDatapoints);
                        }
                    }}
                    isDisabled={index === 0}
                />
                <IconButton
                    aria-label="Move Down"
                    icon={<FaArrowDown />}
                    variant="ghost"
                    colorScheme="blue"
                    onClick={() => {
                        if (index < datapoints.length - 1) {
                            const updatedDatapoints = [...datapoints];
                            const temp = updatedDatapoints[index];
                            updatedDatapoints[index] = updatedDatapoints[index + 1];
                            updatedDatapoints[index + 1] = temp;
                            setDatapoints(updatedDatapoints);
                        }
                    }}
                    isDisabled={index === datapoints.length - 1}
                />
                <IconButton
                    aria-label="Delete"
                    icon={<FaTrash />}
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => removeDatapoint(index)}
                />
            </Flex>
        </Box>
    </Flex>
))}
        
        <HStack mt = "5">
            <Button mr = "3" colorScheme = "green" variant = "outline" onClick = {addDatapoint}>+ Add</Button>
            <Spacer />
            <Button colorScheme = "green" onClick = {handleSaveClick}>Save Changes</Button>
        </HStack>

    </Box>
  )
}

export default DataDatapoints