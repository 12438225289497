import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  Text,
  ModalFooter,
  Code,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';

const CustomDomainModal = ({ hookURL, isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuthContext();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputValue(hookURL);
  }, [hookURL])

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveClick = () => {

    // alert asking if the user has added the A record, and proceed only if so
    const isConfirmed = window.confirm("Have you added the A record? Please confirm to proceed. You will not be able to return to this screen.");

    if (!isConfirmed) {
        return;
    }

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/bot/domain/add/${id}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({domain: inputValue})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response
    }).then(data => {
      
      setLoading(false);
      onClose();
      window.location.reload();

    }).catch(error => {
        alert('Failed to save domain. Please check the URL and try again.');
        setLoading(false);
    })
  };

  const handleTestClick = () => {

    setLoading(true);

    if (!inputValue) {
        alert('Please provide a URL.');
        setLoading(false);
        return;
    }

    if (!inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
        alert('Please provide a valid URL.');
        setLoading(false);
        return;
    }
  

    fetch(`${process.env.REACT_APP_API_URL}/bot/webhook/test`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({webhookUrl: inputValue, botId: id})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response
    }).then(data => {
      
      alert('Webhook successfully sent. Please check your endpoint for the mock object.');
      setLoading(false);


    }).catch(error => {
        alert('Failed to send webhook. Please check the URL and try again.');
        setLoading(false);
    })
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Custom Domain</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text mb={2} fontSize = "sm">
              Replace botsheets.com with your own domain or sub-domain for chat widgets, embeds, and direct links.
            </Text>

            <Text mb={4} fontSize = "sm">
            Botsheets uses <b>A records</b> to point your domain to our servers. You must have access to your domain's DNS settings to use this feature. We configure SSL certificates for your domain automatically. You can use any domain, including subdomains. However, we do not perform non-www to www redirects (or vice versa) automatically.
            </Text>

            <Text mb={4} fontSize = "sm">
              Changes may take up to 48 hours to propagate. If you have any questions, please contact support.
            </Text>

            {/* Instructions and Grid for A Records Setup */}
            <Text mb={2} fontSize="md" fontWeight="semibold">
                DNS Configuration Instructions
            </Text>
            {/* For Single Custom Domains */}
            <Text mb={2} fontSize="sm" fontWeight = "bold">
                Single Custom Domains:
            </Text>
            <Text mb={2} fontSize="sm">
                For a single custom domain, create a single A record as follows:
            </Text>
            <Grid mb={4} bgColor = "gray.100" borderColor = "gray.300" borderWidth = "medium" padding = "4" templateColumns="repeat(4, 1fr)" gap={4}>
                <GridItem colSpan={1}><Text fontWeight="bold">Host/Name</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Type</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">TTL</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Address/Data</Text></GridItem>
                <GridItem colSpan={1}><Text>@</Text></GridItem>
                <GridItem colSpan={1}><Text>A Record</Text></GridItem>
                <GridItem colSpan={1}><Text>Default</Text></GridItem>
                <GridItem colSpan={1}><Text>37.16.29.198</Text></GridItem>
            </Grid>

            {/* For Subdomains */}
            <Text mb={2} fontSize="sm" fontWeight = "bold">
                Only specific subdomains:
            </Text>
            <Text mb={2} fontSize="sm">
                If you'd only like a specific subdomain to show your chatbot, you'll need to create an A record for that subdomain.
            </Text>
            <Grid mb={4} bgColor = "gray.100" borderColor = "gray.300" borderWidth = "medium" padding = "4" templateColumns="repeat(4, 1fr)" gap={4}>
                <GridItem colSpan={1}><Text fontWeight="bold">Host/Name</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Type</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">TTL</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Address/Data</Text></GridItem>
                <GridItem colSpan={1}><Text>my-subdomain</Text></GridItem>
                <GridItem colSpan={1}><Text>A Record</Text></GridItem>
                <GridItem colSpan={1}><Text>Default</Text></GridItem>
                <GridItem colSpan={1}><Text>37.16.29.198</Text></GridItem>
            </Grid>



            <FormControl>
            <Text color = "#000000" fontSize = "lg" fontWeight = "semibold" >Your URL</Text>
            <Text fontSize="sm" mb = "2" >Enter your custom domain or subdomain. This will be the exact URL where your chatbot will be accessible.</Text>
                <Input
                    placeholder="https://example.yourdomain.com/"
                    value={inputValue}
                    onChange={handleInputChange}
                    backgroundColor = "gray.100"
                    borderColor = "gray.300"
                    />
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
            <Button isLoading = {loading} colorScheme='green' onClick={handleSaveClick}>
              Save Domain
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomDomainModal;