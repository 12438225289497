import { Button, Card, CardBody, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast, useDisclosure, Divider} from '@chakra-ui/react'
import DeleteBotButton from '../DeleteBotButton'
import LoadingBox from '../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../hooks/usePublicFetch';
import { useAuthContext } from '../../hooks/useAuthContext';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import UpgradeModal from '../UpgradeModal';
import WebhookModal from './data/components/WebhookModal';
import APIModal from './data/components/APIModal';
import { validUserPlan } from '../../auth/Permissions';

const ManageWebhook = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();
    const [upgradeText, setUpgradeText] = useState('')

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()
    const { isOpen: isOpenWebhook, onOpen: onOpenWebhook, onClose: onCloseWebhook } = useDisclosure()
    const { isOpen: isOpenAPI, onOpen: onOpenAPI, onClose: onCloseAPI } = useDisclosure()

    const handleWebhookClick = () => {
        if (user.data._doc.plan == 'platinum' || user.data._doc.plan == 'ASTier2' || user.data._doc.plan == 'ASTier3'|| user.data._doc.plan == 'ASTier4'|| user.data._doc.plan == 'pro') {
            onOpenWebhook();
        } else {
            setUpgradeText("Upgrade plans to manage webhooks.")
            onOpen();
        }
    }

    const handleAPIClick = () => {
        if (user.data._doc.plan == 'platinum' || user.data._doc.plan == 'ASTier2' || user.data._doc.plan == 'ASTier3'|| user.data._doc.plan == 'ASTier4'|| user.data._doc.plan == 'pro') {
            onOpenAPI();
        } else {
            setUpgradeText("Upgrade plans to use API.")
            onOpen();
        }
    }

    if (isPending) {
    return <div>Loading Webhook / API Settings...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box p = "10" pt = "5" width = "100%" height = {`calc(100vh - 5rem)`}>
        
        <Text mt = "0" fontSize="lg" fontWeight = "bold" >Webhook</Text>
        <Text fontSize="sm">Automatically send data collected by this chatbot to external systems.</Text>
        <Flex mt = "3">
            <Button onClick={handleWebhookClick} w = "100%" borderWidth = "2px" colorScheme="green" variant = "outline">
                Manage Webhook
            </Button>
        </Flex>

        <Text mt = "5" fontSize="lg" fontWeight = "bold" >API</Text>
        <Text fontSize="sm">Programmatically handle conversations with this chatbot using our API.</Text>
        <Flex mt = "3">
            <Button onClick={handleAPIClick} w = "100%" borderWidth = "2px" colorScheme="green" variant = "outline">
                API Key and Documentation
            </Button>
        </Flex>

        <UpgradeModal text = {upgradeText} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
        <WebhookModal hookURL = {data.rules.webhookUrl} isOpen = {isOpenWebhook} onClose = {onCloseWebhook}/>
        <APIModal hookURL = {data.rules.webhookUrl} isOpen = {isOpenAPI} onClose = {onCloseAPI}/>
    </Box>
  )
}

export default ManageWebhook