import React, { useState } from 'react';
import {
  Box,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  IconButton,
  FormControl,
  InputGroup,
  InputRightElement,
  Text,
  ModalFooter
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { set } from 'date-fns';

const URLImportModal = ({ markDataSourceAsDone, storedBot, setStoredBot, addDataSource, changeDatasourceStatus, isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuthContext();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {

    if (!inputValue) {
      alert('Please provide a URL.');
      return;
    }

    onClose();

    const doc = {
        name: inputValue,
        type: 'URL',
        status: 'Processing',
        charCount: 'Processing',
    }

    addDataSource(doc);

    fetch(`${process.env.REACT_APP_API_URL}/create/upload/url`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({url: inputValue})
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorData => {
          throw new Error(JSON.stringify(errorData));
        });
      }
      return response.json()
    }).then(data => {

      const storedDoc = {
        name: inputValue,
        type: 'URL',
        pdfUUid: data.pdfUuid,
        charCount: data.charCount,
        url: data.url,
      }

        // Store predicted category in the storedBot object
        const updatedStoredBot = {
            ...storedBot,
            documents: storedBot.documents ? [...storedBot.documents, storedDoc] : [storedDoc]
        };
        setStoredBot(updatedStoredBot);

        // Store the updated object in sessionStorage
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));

        markDataSourceAsDone(inputValue, "Processed", data.charCount);
        
    }).catch(error => {

        markDataSourceAsDone(inputValue, "Failed")

        try {
          const errorObj = JSON.parse(error.message);
  
          if (errorObj.error === 'Character limit exceeded') {
            alert('This website exceeds your character limit. Please upgrade your plan to process larger websites.');
          } 
        } catch (e) {
          alert('Invalid URL.');
        }

    })

    // Handle the button click action here
    console.log('Button clicked with input value:', inputValue);
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect a Webpage</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <FormControl>
            <Input
              placeholder="https://"
              value={inputValue}
              onChange={handleInputChange}
              mb={4} // Add margin-bottom to separate input from the button
              backgroundColor = "gray.100"
              borderColor = "gray.300"
            />
              <Text mb = "5" fontSize="sm">Add a link to your about page or help pages. Botsheets will <Link href="https://support.botsheets.com/docs/connected-data" isExternal color='#1772cc'>scrape one link at a time</Link>. You only need to connect a webpage once. We keep AI in sync with changes to your website content.</Text>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
            <Button colorScheme='green' mr={3} onClick={handleButtonClick}>
              Connect Webpage
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default URLImportModal;