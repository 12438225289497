import { Flex, Heading, Input, Center, Box, Text, FormControl, FormLabel, Button, Switch, Tooltip } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Divider from "../components/chat/Divider";
import Footer from "../components/chat/Footer";
import Messages from "../components/chat/Messages";
import Home from "../components/chat/Home";
import Suggestions from "../components/chat/Suggestions";
import Header from "../components/chat/Header";
import { useParams } from "react-router-dom";
import { usePublicFetch } from "../hooks/usePublicFetch";
import { v4 as uuidv4 } from 'uuid';
import FooterTest from "./chat/FooterTest";

const ManageTest = () => {
  const {id} = useParams();

  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showThreeDots, setShowThreeDots] = useState(false); //for the loading animation
  const [suggestions, setSuggestions] = useState(null);
  const [debugMode, setDebugMode] = useState(false);

  const [uuid, setUuid] = useState(''); //uuid for the user
  const { data, error, isPending } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/` + id);

  const [password, setPassword] = useState('');
  const [hideScreen, setHideScreen] = useState(true);

  //create a uuid on page load for the user
  useEffect(() => {
    setUuid(uuidv4())
  }, [])

  if (isPending) {
    return <div></div>
  }

  if (error) {
    return <div>{ error }</div>
  }

  if (data && data.active === false) {
    return (
      <Heading>This chatbot is currently sleeping. If you are the chatbot owner you can wake it up.</Heading>
      )
  }

  if (data && data.rules.welcomeMessage && messages.length === 0) {
    setMessages([{ from: "computer", text: data.rules.welcomeMessage }]);
    setHistory([{ role: 'assistant', content: data.rules.welcomeMessage }]);


    if (data.rules.leadingQuestion === "Yes" && data.rules.additionalWelcomeMessage) {
        setMessages((old) => [...old, { from: "computer", text: data.rules.additionalWelcomeMessage }]);
        setHistory((old) => [...old, { role: 'assistant', content: data.rules.additionalWelcomeMessage }]);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    //make a post call to the backend to check if the password is correct
    const response = await fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password
      }),
    });

    //response is {"unlocked": true/false}, if true then set private to false
    const data = await response.json();

    if (data.unlocked === true) {
      setHideScreen(false);
      console.log('unlocked')
    }

  };


  if (data && data.private && hideScreen) {
    // Render password protection UI
    return (
      <Center height="100vh">
        <Box p={4} borderWidth="1px" borderRadius="md" textAlign="center">

              <Text fontSize="xl" fontWeight="bold">
                {data.name}
              </Text>
              <form onSubmit={handleSubmit}>
                <FormControl mt={4}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                  Submit
                </Button>
              </form>
        </Box>
      </Center>
  );
}

  const handleSendMessage = async () => {
    
    if (!inputMessage.trim().length) {
      //console.log('empty msg');
      return;
    }

    setLoading(true)
    setShowThreeDots(true);
    setMessages((old) => [...old, { from: "me", text: inputMessage }]);
    setHistory((old) => [...old, { role: 'user', content: inputMessage }])
    setInputMessage("");

    if (inputMessage.length > 505) {
      setInputMessage(inputMessage.substring(0, 505));
    }

    if (messages.length > 100) {
      setMessages((old) => [...old, { from: "computer", text: "I'm sorry, this conversations approaching its length limit, please refresh if you have additional questions!" }]);
      setLoading(false);
      setShowThreeDots(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/` + id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: inputMessage,
          history,
          uuid,
          test: debugMode
        }),
      });

      let accumulatedData = '';

      const contentType = response.headers.get('Content-Type');
      console.log('contentType', contentType)
      if (contentType.includes('text/plain')) {

        setHistory((old) => [...old, { role: 'assistant', content: '' }]);
        setMessages((old) => [...old, { from: "computer", text: '' }]);
        setShowThreeDots(false);

        // Here we start prepping for the streaming response
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        const loopRunner = true;

        while (loopRunner) {
          // Here we start reading the stream, until its done.
          const { value, done } = await reader.read();
          if (done) {
            setLoading(false);
            setShowThreeDots(false);
            break;
          }
          // create a new history and messages object
          accumulatedData += decoder.decode(value, { stream: true });
          const decodedChunk = decoder.decode(value, { stream: true });

          const transformedData = { from: 'computer', text: accumulatedData };
          const transformedHistoryData = { role: 'assistant', content: accumulatedData };
      
          console.log('decodedChunk', decodedChunk);
          setHistory(oldHistory => {
            const newHistory = oldHistory.slice(0, -1); // Remove the last message
            return [...newHistory, transformedHistoryData]; // Append the updated message
          });
      
          setMessages(oldMessages => {
            const newMessages = oldMessages.slice(0, -1); // Remove the last message
            return [...newMessages, transformedData]; // Append the updated message
          });
        }

      } else {
        const data = await response.json();
        
        if (data.error) {
          throw new Error(data.error);
        } else {

          const transformedData = data.map((str) => {
            if (str && str !== '') {
              return { from: 'computer', text: str };
            }
            return null; // Skip empty strings
          });
      
          const nonEmptyObjects = transformedData.filter((obj) => obj !== null);

          const transformedHistoryData = data.map((str) => {
            if (str && str !== '') {
              return { role: 'assistant', content: str };
            }
            return null; // Skip empty strings
          });
      
          const nonEmptyHistoryObjects = transformedHistoryData.filter((obj) => obj !== null);

          setHistory((old) => [...old, ...nonEmptyHistoryObjects]);

          setMessages((old) => [...old, ...nonEmptyObjects]);
        }

      }
      /* setSuggestions([['Need more help?'], ["Ask another question"]]); */
      setLoading(false);
      setShowThreeDots(false);
      

    } catch (error) {
      setLoading(false);
      setShowThreeDots(false);
      setMessages((old) => [...old, { from: "computer", text: "Hmm, I think our wires got crossed. Can you say that again?" }]);
    }
  };

  console.log('loading', loading)
  const handleReset = () => {
    setMessages([]);
    setHistory([]);
    setInputMessage("");
    setSuggestions(null);
    setUuid(uuidv4());

    if (data && data.rules.welcomeMessage && messages.length === 0) {
        setMessages([{ from: "computer", text: data.rules.welcomeMessage }]);
        setHistory([{ role: 'assistant', content: data.rules.welcomeMessage }]);
    
    
        if (data.rules.additionalWelcomeMessage) {
            setMessages((old) => [...old, { from: "computer", text: data.rules.additionalWelcomeMessage }]);
            setHistory((old) => [...old, { role: 'assistant', content: data.rules.additionalWelcomeMessage }]);
        }
      }
  }



  return (
    <Flex w="100%" height = {`calc(100vh - 5rem)`} justify="center" align="center">
      <Flex w="100%" h="100%" flexDir="column">
        <Flex flexDir = "row" align="center">
          <Tooltip label="In debug mode, a bot will explain results it gathers from connected Google Sheets. This is only visible from the Test Bot screen and won't affect your customers view.">
            <Text fontWeight="bold" top="0" right="0" m = "4">
              Enable Debug Mode:
            </Text>
          </Tooltip>
          <Switch isChecked={debugMode} onChange={() => setDebugMode(!debugMode)} />
        </Flex>
        <Divider />
        <Messages showThreeDots={showThreeDots} loading = {loading} messages={messages} bot_id = {id} testBot = {true} />
        <Divider />
        <FooterTest
          showTreeDots={showThreeDots}
          handleReset={handleReset}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSendMessage={handleSendMessage}
          loading={loading}
          powered_by = {data.powered_by}
          customTypeMessage= {data.customTypeMessage}
        />
      </Flex>
    </Flex>
  );
};

export default ManageTest;
