import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  Text,
  ModalFooter,
  Code,
  Grid,
  GridItem,
  Heading,
  Stack,
  Badge,
  Divider,
} from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';

const VerifyDNSModal = ({ url, hookURL, isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuthContext();
  const {id} = useParams();
  const [dnsStatus, setDnsStatus] = useState({});

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!url) {
        return;
    }

    if (!isOpen) {
        return;
    }

    // fetch status of the domain from api
    fetch(`${process.env.REACT_APP_API_URL}/bot/domain/status/`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({domain: url})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }).then(data => {
      
      setDnsStatus(data.data);
      setLoading(false);

    }).catch(error => {
        alert('Failed to fetch DNS status.');
        setLoading(false);
    })
  }, [url, isOpen])


  const handleDeleteClick = () => {

    const isConfirmed = window.confirm("Are you sure you want to delete this domain?");

    if (!isConfirmed) {
        return;
    }

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/bot/domain/delete/`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({domain: url})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response
    }).then(data => {
      
      window.location.reload();
      onClose();


    }).catch(error => {
        alert('Failed to delete domain.');
        setLoading(false);
    })
  };


  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Verify DNS Settings (for {url ? url : ''})</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Stack spacing={4} mb = "5">
                <Text>
                Site Hit: <Badge colorScheme={dnsStatus.has_ssl ? 'green' : 'red'}>{dnsStatus.apx_hit ? 'Confirmed' : 'Pending'}</Badge>
                </Text>
                <Text>
                SSL Status: <Badge colorScheme={dnsStatus.has_ssl ? 'green' : 'red'}>{dnsStatus.has_ssl ? 'Confirmed' : 'Pending'}</Badge>
                </Text>
                <Text>
                Is Resolving: <Badge colorScheme={dnsStatus.is_resolving ? 'green' : 'red'}>{dnsStatus.is_resolving ? 'Confirmed' : 'Pending'}</Badge>
                </Text>
                <Text>
                Status: {dnsStatus.status_message}
                </Text>
                <Text>
                {dnsStatus.user_message}
                </Text>
            </Stack>

            <Divider />

            <Text mt = {4} mb={4} fontSize = "sm">
            Botsheets uses <b>A records</b> to point your domain to our servers. You must have access to your domain's DNS settings to use this feature. We configure SSL certificates for your domain automatically. You can use any domain, including subdomains. However, we do not perform non-www to www redirects (or vice versa) automatically.
            </Text>

            <Text mb={4} fontSize = "sm">
              Changes may take up to 48 hours to propagate. If you have any questions, please contact support.
            </Text>

            {/* Instructions and Grid for A Records Setup */}
            <Text mb={2} fontSize="md" fontWeight="semibold">
                DNS Configuration Instructions
            </Text>
            {/* For Single Custom Domains */}
            <Text mb={2} fontSize="sm" fontWeight = "bold">
                Single Custom Domains:
            </Text>
            <Text mb={2} fontSize="sm">
                For a single custom domain, create a single A record as follows:
            </Text>
            <Grid mb={4} bgColor = "gray.100" borderColor = "gray.300" borderWidth = "medium" padding = "4" templateColumns="repeat(4, 1fr)" gap={4}>
                <GridItem colSpan={1}><Text fontWeight="bold">Host/Name</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Type</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">TTL</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Address/Data</Text></GridItem>
                <GridItem colSpan={1}><Text>@</Text></GridItem>
                <GridItem colSpan={1}><Text>A Record</Text></GridItem>
                <GridItem colSpan={1}><Text>Default</Text></GridItem>
                <GridItem colSpan={1}><Text>37.16.29.198</Text></GridItem>
            </Grid>

            {/* For Subdomains */}
            <Text mb={2} fontSize="sm" fontWeight = "bold">
                Only specific subdomains:
            </Text>
            <Text mb={2} fontSize="sm">
                If you'd only like a specific subdomain to show your chatbot, you'll need to create an A record for that subdomain.
            </Text>
            <Grid mb={4} bgColor = "gray.100" borderColor = "gray.300" borderWidth = "medium" padding = "4" templateColumns="repeat(4, 1fr)" gap={4}>
                <GridItem colSpan={1}><Text fontWeight="bold">Host/Name</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Type</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">TTL</Text></GridItem>
                <GridItem colSpan={1}><Text fontWeight="bold">Address/Data</Text></GridItem>
                <GridItem colSpan={1}><Text>my-subdomain</Text></GridItem>
                <GridItem colSpan={1}><Text>A Record</Text></GridItem>
                <GridItem colSpan={1}><Text>Default</Text></GridItem>
                <GridItem colSpan={1}><Text>37.16.29.198</Text></GridItem>
            </Grid>

          </Box>
        </ModalBody>
        <ModalFooter>
            <Button onClick = {handleDeleteClick} mr = "2" isLoading = {loading} colorScheme='red'>
              Delete Domain
            </Button>
            <Button isLoading = {loading} colorScheme='green' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VerifyDNSModal;