import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  IconButton,
  FormControl,
  InputGroup,
  InputRightElement,
  Text,
  ModalFooter,
  Flex,
  Spacer
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { set } from 'date-fns';
import { FileUploader } from "react-drag-drop-files";

const PDFImportModal = ({ markDataSourceAsDone, storedBot, setStoredBot, addDataSource, changeDatasourceStatus, isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuthContext();
  const fileTypes = ["PDF"];

  const [file, setFile] = useState(null);
  const [fileArray, setFileArray] = useState([]);

  const handleChange = (file) => {
    setFile(file);
  };

  const deleteFile = (index) => {
    setFile(null)
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleButtonClick = () => {

    if (!file) {
      alert('Please provide a file.');
      return;
    }

    onClose();

    const doc = {
        name: file.name,
        type: 'File',
        status: 'Processing',
        charCount: 'Processing'
    }

    addDataSource(doc);

    const formData = new FormData();
    formData.append("file", file);
  
    fetch(`${process.env.REACT_APP_API_URL}/create/upload/pdf`, {
        method: 'POST',
        headers: {'Authorization': `Bearer ${user.data.token}`},
        body: formData
    })
    .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            throw new Error(JSON.stringify(errorData));
          });
        }
        return response.json()
    }).then(data => {

        const storedDoc = {
            name: file.name,
            type: 'file',
            pdfUUid: data.pdfUuid,
            charCount: data.charCount,
            originalname: data.originalname,
        }

        // Store predicted category in the storedBot object
        const updatedStoredBot = {
            ...storedBot,
            documents: storedBot.documents ? [...storedBot.documents, storedDoc] : [storedDoc]
        };
        setStoredBot(updatedStoredBot);

        // Store the updated object in sessionStorage
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));

        markDataSourceAsDone(file.name, "Processed", data.charCount);
        
    }).catch(error => {

      markDataSourceAsDone(file.name, "Failed")

      try {
        const errorObj = JSON.parse(error.message);

        if (errorObj.error === 'Character limit exceeded') {
          alert('This document exceeds your character limit. Please upgrade your plan to process larger documents.');
        } 
      } catch (e) {
        alert('Your file could not be processed. Common errors include: file too large (> 10 MB), file not a PDF, file name contains non ASCII Characters, or file corrupted.');
      }

    })

  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
      <ModalHeader>Upload a PDF Document</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <FormControl>
            <Text mb = "5" p = "2" fontSize="sm">Add a file max 30MB</Text>

            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />

            {file && <Flex mt = "3" alignItems = "center">
                <Text>{file.name}</Text>
                <Spacer />
                <Button colorScheme = "red" onClick={() => deleteFile()}>Delete</Button>
            </Flex>}

            <Text mb = "5" p = "2" fontSize="sm">Your Botsheets plan may have document character limits, so shorter docs are faster to process and more cost effective for you.</Text>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
            <Button colorScheme='green' mr={3} onClick={handleButtonClick}>
              Upload PDF
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PDFImportModal;