import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Flex, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Link, FormControl, FormLabel, Input, Stack, Checkbox } from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';
import { set } from 'date-fns';

const SheetSettingsModal = ({ isOpen, onClose, data, updateBotData }) => {
  // State to hold the input value
  const [desc, setDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();
  const {id} = useParams();

  const [GSheets, setGSheets] = useState([]);

  // Handler to toggle semanticFallback for a Google Sheet
  const handleToggleFallback = (index) => {
    const newGSheets = [...GSheets];
    const sheet = newGSheets[index];
    sheet.semanticDisabled = !sheet.semanticDisabled; // Toggle the boolean
    setGSheets(newGSheets);
  };
  

  console.log("DATA", data.rules.GSheetsURL, data.rules.GSheetsDesc)

  useEffect(() => {
    if (data.GSheets) {
        setGSheets(data.GSheets);
    }
  }, [data.GSheets])

console.log("GSheets", GSheets)

  const saveSettingsChanges = () => {

    setLoading(true)

    // post request with the url
    fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
        method: 'PATCH',
        headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
        body: JSON.stringify({'GSheets': GSheets})
    })
    .then(response => {
      return response.json()
    }).then(data => {
      console.log(data)
      if (data.error) {
        alert(data.error);
        setLoading(false)
        return;
      }
      updateBotData(data)
      alert("Successfully updated Google Sheet settings.")
      setLoading(false)
      onClose();
    }).catch(error => {
      setLoading(false)
      console.log(error)
      alert('There was an error saving the Google Sheet settings. Please try again.')
    })
}



  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Sheet Settings (Beta)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Text color="gray.600">
                Botsheets will query your Google Sheet but can fallback and perform a semantic search of your data when the AI model is not confident in its initial response. A semantic search search similar rows to generate a response and while this can occasionally lead to AI hallucination and incorrect responses it also increases the chances of delivering a relevant response. 
            </Text>

            <Text color="gray.600" mt = "5">
                Chatbots will fallback to using semantic search by default. Uncheck to disable semantic search.
            </Text>

            <Flex justifyContent = "space-between" mt = "5">
                <Text my="4" fontWeight = "bold">Data Source</Text>
                <Text my="4" fontWeight = "bold">Enable Semantic Search</Text>
            </Flex>

            <Stack direction="column" spacing={4}>
                {GSheets && GSheets.map((sheet, index) => (
                <Box key={index} display="flex" justifyContent="space-between" alignItems="center" width="full">
                    <Text noOfLines={1}>
                    {sheet.desc.length > 20 ? `${sheet.desc.substring(0, 20)}...` : sheet.desc}
                    </Text>
                    <Checkbox
                        isChecked={!sheet.semanticDisabled || false}
                        onChange={() => handleToggleFallback(index)}
                        sx={{
                            '.chakra-checkbox__control': {
                            borderWidth: '2px', // Makes the border thicker
                            borderColor: 'green.500', // Sets the border color to green
                            },
                            '.chakra-checkbox__control[data-checked]': {
                            borderColor: 'green.500', // Keeps the border green when checked
                            bg: 'green.500', // Optional: change background when checked
                            }
                        }}
                    />
                </Box>
                ))}
            </Stack>

          </FormControl>
        </ModalBody>
        <ModalFooter>
            <Button isLoading = {loading} colorScheme = "green" onClick={saveSettingsChanges}>
                Save Changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SheetSettingsModal;
