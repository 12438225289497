import { useState, useEffect } from 'react';
import { Box, Button, Text, Link, UnorderedList, ListItem, HStack, Spacer, Checkbox, useDisclosure, TableContainer, Table, TableCaption, Thead, Tr, Th, Tfoot, Td, Tbody, Switch } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import LoadingBoxText from '../common/LoadingBoxText';
import AddDatapointModal from './components/AddDatapointModal';

const Step4 = ({goToNext, goToPrevious}) => {

    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

    const [storedBot, setStoredBot] = useState(null);
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(true);

    const [selectedDatapoints, setSelectedDatapoints] = useState([]);
    const [allDatapoints, setAllDatapoints] = useState([]);

    useEffect(() => {

        const storedData = sessionStorage.getItem('storedBot');
        const storedAllData = sessionStorage.getItem('step4_all');
        const storedSelectedData = sessionStorage.getItem('step4_selected');
        
        if (storedData) {
          // If data exists in sessionStorage, parse it back to an object
          const parsedObject = JSON.parse(storedData);
          console.log(parsedObject)

          setStoredBot(parsedObject);

          if (storedAllData && storedSelectedData) {
            const parsedAllData = JSON.parse(storedAllData);
            const parsedSelectedData = JSON.parse(storedSelectedData);

            setAllDatapoints(parsedAllData);
            setSelectedDatapoints(parsedSelectedData);
            setLoading(false);
            return;
          }

          fetch(`${process.env.REACT_APP_API_URL}/create/generate/datapoints`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
            body: JSON.stringify({category: parsedObject.business_category, business_description: parsedObject.business_description})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
          console.log(data);
          const datapoints = data.questions
          datapoints.forEach(datapoint => {
            datapoint.required = false;
          })
          setAllDatapoints(datapoints);
          setLoading(false);
        }).catch(error => {
          console.log(error);
          setAllDatapoints([
            {
              id: 1,
              question: 'What is the name of the company?',
              extracted_data: 'Company Name',
              description: '',
              example_question: 'What is the name of the company?',
              required: false,
            }
          ])
          setLoading(false);
        })
          
        }
      }, []);


    const handleContinueClick = () => {
        if (selectedDatapoints.length === 0) {
          alert('Select at least one data point to continue. We recommend selecting a name so your chat sessions are more personal');
          return;
        }

        console.log(selectedDatapoints)

        //map through allDatapoints, and if it's in selectedDatapoints, set the selected datapoint required to the value in allDatapoints
        const updatedSelectedDatapoints = [...selectedDatapoints];
        const updatedAllDatapoints = [...allDatapoints];

        updatedAllDatapoints.forEach(datapoint => {
          const index = updatedSelectedDatapoints.findIndex(item => item.id === datapoint.id);
          if (index !== -1) {
            updatedSelectedDatapoints[index].required = datapoint.required;
          }
        }
        )

        const updatedStoredBot = {
          ...storedBot,
          datapoints: updatedSelectedDatapoints
        };

        setStoredBot(updatedStoredBot);
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));
        sessionStorage.setItem('step4_all', JSON.stringify(allDatapoints));
        sessionStorage.setItem('step4_selected', JSON.stringify(updatedSelectedDatapoints));

        goToNext();
    
      };

    const handlePreviousClick = () => {

      sessionStorage.setItem('step4_all', JSON.stringify(allDatapoints));
      sessionStorage.setItem('step4_selected', JSON.stringify(selectedDatapoints));

      goToPrevious();

    };

    const addNewDatapoint = (name, description, example_question) => {
      const newDatapoint = {
        id: allDatapoints.length + 1,
        question: example_question,
        extracted_data: [name],
        description: description,
        example_question: example_question,
        required: false
      }

      setAllDatapoints([...allDatapoints, newDatapoint]);
    }

    const addSelectedDatapoints = (datapoint) => {
      // Check if the datapoint is already in the selectedDatapoints array
      const isSelected = selectedDatapoints.some(item => item.id === datapoint.id);
    
      // Create a copy of selectedDatapoints to avoid mutating the original data
      const updatedSelectedDatapoints = [...selectedDatapoints];
    
      if (isSelected) {
        // Remove the datapoint from selectedDatapoints
        setSelectedDatapoints(updatedSelectedDatapoints.filter(item => item.id !== datapoint.id));
      } else {
        // Add the datapoint to selectedDatapoints
        setSelectedDatapoints([...updatedSelectedDatapoints, datapoint]);
      }
    }

    const setDatapointRequired = (datapoint) => {
      // set the datapoint to required in allDatapoints
      const updatedAllDatapoints = [...allDatapoints];
      const updatedDatapoint = {...datapoint};
      updatedDatapoint.required = !updatedDatapoint.required;
      const index = updatedAllDatapoints.findIndex(item => item.id === datapoint.id);
      updatedAllDatapoints[index] = updatedDatapoint;
      setAllDatapoints(updatedAllDatapoints);
    }
      

    if (loading) {
        return <LoadingBoxText />;
    }

    return (
        <Box>
            <Box display = 'flex' flexDir = "column">
                <Text color = "#EC6060" fontSize = "xl" fontWeight = "semibold">Collect data from users during a chat session</Text>
                <Text mb = "5" color = "gray.500" fontSize="md">
                <UnorderedList>
                    <ListItem>AI will generate questions using selected data points.</ListItem>
                    <ListItem>Automatically capture data collected <Link href="https://support.botsheets.com/docs/collecting-data" isExternal color='#1772cc'>in Google Sheets</Link>, by email, with a Webhook, or using our API.</ListItem>
                    <ListItem>Your chatbot will be persistent about collecting required data.</ListItem>
                    <ListItem>At a minimum, collect a name to personalize your conversational experience.</ListItem>
                </UnorderedList>
                </Text>
            </Box>

              <TableContainer mt = "5" mb = "5" >
                <Table variant='simple'>
                    <Thead>
                    <Tr>
                        <Th>Select</Th>
                        <Th>Data Point</Th>
                        <Th>Description</Th>
                        <Th>Required</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {allDatapoints && allDatapoints.map((datapoint) => (
                    <Tr key = {datapoint.id}>
                        <Td><Checkbox border = "2px" borderRadius = "4" size = "md" colorScheme = "green" defaultChecked = {selectedDatapoints.some(item => item.id === datapoint.id)} onChange={() => addSelectedDatapoints(datapoint)} /></Td>
                        <Td><Box whiteSpace="normal"><Text fontWeight = "semibold">{datapoint.extracted_data}</Text></Box></Td>
                        <Td><Box whiteSpace="normal">{datapoint.description}</Box></Td>
                        <Td><Switch isChecked = {datapoint.required} onChange={() => setDatapointRequired(datapoint)} /></Td>
                    </Tr>
                    ))}
                    </Tbody>
                </Table>
              </TableContainer>

            <Button colorScheme = "blue" mb = "5" onClick = {onModalOpen}>+ Add Custom Data Point</Button>
            
            <HStack mt = "5">
                <Button mr = "3" colorScheme = "green" onClick={handlePreviousClick}>Previous</Button>
                <Spacer />
                <Button colorScheme = "green" onClick={handleContinueClick}>Continue</Button>
            </HStack>

            <AddDatapointModal isOpen={isModalOpen} onClose={onModalClose} addNewDatapoint={addNewDatapoint}/>
        </Box>
    )
}

export default Step4