import React from "react";
import { Flex, Button, Input, InputGroup, Text, VStack } from "@chakra-ui/react";

const FooterTest = ({ handleReset, inputMessage, setInputMessage, handleSendMessage, loading, customTypeMessage }) => {

    console.log('customtypemessage', customTypeMessage)
  return (
    <VStack bgColor = "gray.200" pb = "2">
    <Flex w="100%" mt="5" flexDirection = "row">
        <InputGroup mx = "5">
            <Input
                maxLength = {500}
                bgColor = "white"
                placeholder= {customTypeMessage ? customTypeMessage : "Type your message..."}
                _focus={{
                border: "1px solid black",
                }}
                onKeyPress={(e) => {
                if (e.key === "Enter") {
                    if (loading) {
                        return;
                    }
                    handleSendMessage();
                }
                }}
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}          
            />
        </InputGroup>
        <Button isDisabled={loading} colorScheme = "green" onClick = {() => handleSendMessage()} pl = "6" pr = "6" >
            Send
        </Button>
        <Button isDisabled={loading} onClick = {() => handleReset()} colorScheme = "gray" variant = "outline" bgColor = "white" pl = "6" pr = "6" mr = "4" ml = "4" >
            Reset
        </Button>
    </Flex>
    <Text fontSize = "xs"> </Text>
    </VStack>
  );
};

export default FooterTest;
