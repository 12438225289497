'use client'

import {
  Box,
  Button,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useColorModeValue,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Flex,
  ModalHeader,
  ModalCloseButton,
  Progress
} from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { FaCircleXmark } from 'react-icons/fa6'
import { freeOptions, freeNonOptions, liteOptions, liteNonOptions, proOptions, proNonOptions, platinumOptions, ASTier1NonOptions, ASTier1Options, ASTier2NonOptions, ASTier2Options, ASTier3NonOptions, ASTier3Options, ASTier4NonOptions, ASTier4Options } from './PlanDetails'
import { useNavigate, Link } from 'react-router-dom';
import LTDBanner from './LTDBanner'

const PackageTier = ({ user, userPlan, displayTitle, title, options, nonOptions, typePlan, checked = false }) => {
    const getBillingLink = () => {
        fetch(`${process.env.REACT_APP_API_URL}/subscription/`, {
          method: 'GET',
          headers: {'Authorization': `Bearer ${user.data.token}`},
        }).then((res) => {
          res.json().then((data) => {
            window.location.href = data.session.url;
          })
        }
        )
      }

  return (
    <Flex
    py={3}
    direction={{
      base: 'column',
      md: 'row',
    }}
    alignItems={{ md: 'top' }}
  >
    <Flex w={{ base: '100%', md: '20%' }}>
      <Heading mr = "5" size="md">{displayTitle}</Heading>
    </Flex>
    <Flex w={{ base: '100%', md: '50%' }}>
      <List spacing={1} textAlign="start">
        {options && options.map((desc, id) => (
          <ListItem key={desc.id}>
            <ListIcon as={FaCheckCircle} color="green.500" />
            {desc.desc}
          </ListItem>
        ))}
        {nonOptions && nonOptions.map((desc, id) => (
          <ListItem key={desc.id}>
            <ListIcon as={FaCircleXmark} color="red.500" />
            {desc.desc}
          </ListItem>
        ))}
      </List>
    </Flex>
    <Flex w={{ base: '100%', md: '20%' }}>
      <Heading mr = "5" textAlign = "left" size="md">{typePlan}</Heading>
    </Flex>
    <Flex w={{ base: '100%', md: '20%' }}>
      {userPlan && userPlan.toLowerCase() === title.toLowerCase() ? (
        <Button
          size="md"
          color='white'
          bgColor='gray.600'
          _hover={{
            bg: 'black',
            color: 'white',
          }}
          onClick={getBillingLink}
        >
          Current Plan
        </Button>
      ) : (
        <Button
          size="md"
          color='white'
          bgColor='#00a96c'
          _hover={{
            bg: '#006c4b',
            color: 'white',
          }}
          onClick={getBillingLink}
        >
          Select Plan
        </Button>
      )}
    </Flex>
  </Flex>
  

  )
}
const PricingModal = ({user, isOpen, onClose, userPlan}) => {

  const navigate = useNavigate();
    
  return (
    <Modal isOpen={isOpen} onClose = {onClose}>
        <ModalOverlay>
        
        <ModalContent maxW="56rem">
        <ModalHeader>Monthly Usage &amp; Plans</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

       
          {/* Progress Bars */}
        <Stack spacing={4} width={'100%'} direction={'column'} mb={6}>
          <Box>
            <Text color = "#495469" fontSize="md" fontWeight="bold" mb={2}>Chatbots ({user.data._doc.botCount}/{user.data.limits.botCount} Chatbots)</Text>
            <Progress value={user.data._doc.botCount / user.data.limits.botCount * 100} size="sm" colorScheme="orange" />
          </Box>
          <Box>
            <Text color = "#495469" fontSize="md" fontWeight="bold" mb={2}>Message Credits ({user.data._doc.chatCount}/{user.data.limits.chatCount} Message Credits)</Text>
            <Progress value={user.data._doc.chatCount / user.data.limits.chatCount * 100} size="sm" colorScheme="orange" />
          </Box>
          <Box>
            <Text color = "#495469" fontSize="md" fontWeight="bold" mb={2}>Document Character Limit ({user.data._doc.charCount}/{user.data.limits.charCount} Characters)</Text>
            <Progress value={user.data._doc.charCount / user.data.limits.charCount * 100} size="sm" colorScheme="orange" />
          </Box>
          <Box>
            <Text color = "#495469" fontSize="md" fontWeight="bold" mb={2}>Write Sheet Rows ({user.data._doc.writeCount}/{user.data.limits.writeCount} Rows)</Text>
            <Progress value={user.data._doc.writeCount / user.data.limits.writeCount * 100} size="sm" colorScheme="orange" />
          </Box>
         {/* Banner for Lifetime Deal Offer 
         <LTDBanner onClose={() => onClose()} />  */}
        </Stack>
        <Box py={2} px={5} width="full">
        <Stack spacing={4} width={'100%'} direction={'column'}>

            {userPlan && userPlan === "ASTier1" && (
              <>
                <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'AppSumo Tier 1'} title={'ASTier1'} typePlan="N/A" options={ASTier1Options} nonOptions={ASTier1NonOptions} />
                <Divider />
              </>
            )}

            {userPlan && userPlan === "ASTier2" && (
              <>
                <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'AppSumo Tier 2'} title={'ASTier2'} typePlan="N/A" options={ASTier2Options} nonOptions={ASTier2NonOptions} />
                <Divider />
              </>
            )}

            {userPlan && userPlan === "ASTier3" && (
              <>
                <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'AppSumo Tier 3'} title={'ASTier3'} typePlan="N/A" options={ASTier3Options} nonOptions={ASTier3NonOptions} />
                <Divider />
              </>
            )}

            {userPlan && userPlan === "ASTier4" && (
              <>
                <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'AppSumo Tier 4'} title={'ASTier4'} typePlan="N/A" options={ASTier4Options} nonOptions={ASTier4NonOptions} />
                <Divider />
              </>
            )}

            {userPlan && userPlan !== "ASTier1" && userPlan !== "ASTier2" && userPlan !== "ASTier3" && userPlan !== "ASTier4" && (
              <>
                <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'Free'} title={'Free'} typePlan="Free" options={freeOptions} nonOptions={freeNonOptions} />
                <Divider />
              </>
            )}
            <PackageTier
            user = {user}
            userPlan = {userPlan}
            displayTitle = {'Lite'}
            title={'Lite'}
            checked={true}
            typePlan="$29.00/mo"
            options={liteOptions}
            nonOptions={liteNonOptions}
            />
            <Divider />
            <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'Pro'} title={'Pro'} typePlan="$99.00/mo" options={proOptions} nonOptions={proNonOptions} />
            <Divider />
            <PackageTier user = {user} userPlan = {userPlan} displayTitle = {'Unlimited'} title={'Platinum'} typePlan="$279.00/mo" options={platinumOptions} />
        </Stack>
        </Box>
        </ModalBody>
        </ModalContent>
        </ModalOverlay>
    </Modal>
  )
}

export default PricingModal