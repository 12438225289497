import { useState, useEffect } from 'react';
import { Box, Button, UnorderedList, ListItem, Textarea, Text, Link, HStack, Spacer, Select } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';

const Step1 = ({goToNext, goToPrevious, handleUpdateTitle}) => {

    const [botDescription, setBotDescription] = useState('');
    const [storedBot, setStoredBot] = useState(null);
    const { user } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [initialBotDescription, setInitialBotDescription] = useState('');

    useEffect(() => {
        // Retrieve the object from sessionStorage
        const storedData = sessionStorage.getItem('storedBot');
    
        if (storedData) {
          // If data exists in sessionStorage, parse it back to an object
          const parsedObject = JSON.parse(storedData);
          setStoredBot(parsedObject);

          if (parsedObject.business_description) {
            setBotDescription(parsedObject.business_description);
            setInitialBotDescription(parsedObject.business_description);
        }

        }

        

      }, []);

    const handleDescriptionChange = (event) => {
        setBotDescription(event.target.value);
    };

    const handleContinueClick = () => {
        setIsLoading(true)
        if (!botDescription) {
          alert('Briefly describe the goal or purpose of your chatbot.');
          setIsLoading(false)
          return;
        }

        if (initialBotDescription == botDescription && storedBot.business_category) {
            goToNext();
            setIsLoading(false)
            return;
        }

        // Set Step 3 and Step 4 to null
        sessionStorage.removeItem('step3_all');
        sessionStorage.removeItem('step3_selected');
        sessionStorage.removeItem('step4_all');
        sessionStorage.removeItem('step4_selected');

    
        fetch(`${process.env.REACT_APP_API_URL}/create/classify/text`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
            body: JSON.stringify({business_description: botDescription})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            const business_category = data.business_category;

            // Store predicted category in the storedBot object
            const updatedStoredBot = {
                ...storedBot,
                business_category,
                business_description: botDescription,
            };

            // Store the updated object in sessionStorage
            sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));

            handleUpdateTitle();

            goToNext();
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
            alert('That is not enough to describe the goal or purpose of your chatbot');
        })
    
      };

    return (
        <Box>
            <Box mb = "5" display = 'flex' flexDir = "column">
                <Text color = "#EC6060" fontSize = "xl" fontWeight = "semibold" >What is the goal or purpose of your chatbot?</Text>
                <Text mb = "5" color = "gray.500" fontSize="md">Give your chatbot a goal so it can answer questions but also ask questions to collect valuable and actionable data from a website visitor.</Text>
                <Text mb = "1" color = "gray.500" fontSize="md">Here are some good examples:</Text>
                <Text mb = "5" color = "gray.500" fontSize="md">
                <UnorderedList>
                    <ListItem>To sell real estate properties in NYC.</ListItem>
                    <ListItem>To generate leads for a pool cleaning business, Acme Pool Cleaning.</ListItem>
                    <ListItem>To screen potential candidates for a software developer position at Google.</ListItem>
                    <ListItem>To provide customer support to people who purchased from my Shopify store.</ListItem>
                </UnorderedList>
                </Text>
                <Textarea
                maxLength = "150"
                height = "30px"
                isDisabled = {isLoading}
                placeholder="Enter the goal or purpose of your chatbot here"
                value={botDescription}
                onChange={handleDescriptionChange}
                size = "md"
                mb='16px'
                mt = "4px"
                backgroundColor = "gray.100"
                borderColor = "gray.300"
                ></Textarea>
                <Text mb = "5" color = "gray.500" fontSize="md">Building bots with  <Link href="https://app.botsheets.com" isExternal color='#1772cc'>Manychat</Link>? Add the Botsheets Action.</Text>
                
            </Box>
            <HStack>
                <Button mr = "3" colorScheme = "blackAlpha" onClick={() => {
                    window.location.href = window.location.origin;
                }}>Cancel</Button>
                <Spacer />
                <Button isLoading = {isLoading} colorScheme = "green" onClick={handleContinueClick}>Continue</Button>
            </HStack>
        </Box>
    )
}

export default Step1