import { Button, Card, CardBody, CardFooter, Input, CardHeader, Heading, Select, SimpleGrid, Text, Link, Stack, StackDivider, Box, Code, Flex, useToast, useDisclosure, Divider, Menu, MenuButton, MenuList, MenuItem, MenuDivider} from '@chakra-ui/react'
import DeleteBotButton from '../DeleteBotButton'
import LoadingBox from '../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../hooks/usePublicFetch';
import { useAuthContext } from '../../hooks/useAuthContext';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import UpgradeModal from '../UpgradeModal';
import { validUserPlan } from '../../auth/Permissions';
import CustomDomainModal from './data/components/CustomDomainModal';
import VerifyDNSModal from './data/components/VerifyDNSModal';

const CustomDomainDropdown = ({ user, onOpenDomain, id, handleNewDomainClick}) => {

    const handleDomainSelect = (domain) => {

        // fetch status of the domain from api
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({cnameDomain: domain})
          })
          .then(response => {
              if (!response.ok) {
                  throw new Error(response.status)
              }
              return response.json()
          }).then(data => {
              window.location.reload()
          }
          ).catch(error => {
              console.log(error)
          })
    }



  return (
    <Menu>
      <MenuButton as={Button} w="100%" colorScheme="green" variant="outline">
        Select or Add Chatbot Domain
      </MenuButton>
      <MenuList>
        {user.data._doc.domains && user.data._doc.domains.map((domain, index) => (
          <MenuItem onClick = {() => handleDomainSelect(domain)} key={index}>{domain}</MenuItem>
        ))}
        <MenuDivider />
        <MenuItem onClick={handleNewDomainClick} color="green.500">+ New Custom Domain</MenuItem>
      </MenuList>
    </Menu>
  );
};

const ManageGeneral = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();
    const [upgradeText, setUpgradeText] = useState('')

    const [chatbotName, setChatbotName] = useState('');
    const [openAIKey, setOpenAIKey] = useState('')
    const [keyType, setKeyType] = useState("botsheets");
    const [domainType, setDomainType] = useState("botsheets");

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()
    const { isOpen: isOpenDNS, onOpen: onOpenDNS, onClose: onCloseDNS } = useDisclosure()
    const { isOpen: isOpenDomain, onOpen: onOpenDomain, onClose: onCloseDomain } = useDisclosure()


    const handleSelectChange = (e) => {
        const selectedKey = e.target.value;
        setKeyType(selectedKey);
    
        if (selectedKey === "botsheets") {
          setOpenAIKey(""); // Reset openAIKey if 'Botsheets Key' is selected
        }
      };


    const handleInputChange = (e) => {
        setChatbotName(e.target.value);
    };

    const handleNewDomainClick = () => {
        // Check Limits and Plan

        if (!validUserPlan(3, user.data._doc.plan)) {
            setUpgradeText("Upgrade your plan to add a new custom domain.")
            onOpen();
        } else if (user.data._doc.domains.length >= 1) {
            alert('Currently only 1 custom domain is supported per account.')
        } else {
            onOpenDomain();
        }

    }

    const handleOpenAIKeyChange = (e) => {
        if (user.data._doc.plan == 'platinum' || user.data._doc.plan == 'pro' || user.data._doc.plan == 'ASTier2' || user.data._doc.plan == 'ASTier3' || user.data._doc.plan == 'ASTier4') {
            setOpenAIKey(e.target.value);
        } else {
            setOpenAIKey('');
            setUpgradeText("Upgrade your plan to add your own OpenAI Key and get unlimited messaging.")
            onOpen();
        }
    };

    const handleSaveDomainClick = () => {

        if (domainType === "botsheets") {
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'cnameDomain': ''})
        })

        .then(
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })
        ) 
        } else {
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
                })
        }  
    }

    const handleSaveClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'title': chatbotName, 'rules.chatbotName': chatbotName, 'rules.openAIKey': openAIKey})
        })

        .then(
            toast({
                title: `Successfully Saved`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })
        )
    };

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleDelete = () => {
        // Perform the delete action here
        // Close the modal afterward
        setIsDeleteModalOpen(false);
    };

    const handleDeleteClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.data.token}`}
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            window.location.href = '/'
        }
        ).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (data) {
            setChatbotName(data.title);
            setOpenAIKey(data.rules.openAIKey)
            setKeyType(data.rules.openAIKey ? "customOpenAI" : "botsheets")
            setDomainType(data.cnameDomain ? "customDomain" : "botsheets")
        }
    }, [data])


    if (isPending) {
    return <div>Loading Chatbot Settings...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box p = "10" pt = "5" width = "100%" height = {`calc(100vh - 5rem)`}>

        {data.type == 'analysis' && (<>
        <Text mb = "3" fontSize="lg" fontWeight = "bold" >Data Source</Text>
        <Button mb = "3" colorScheme = "green" w = "100%" onClick={() => window.open(data.url, '_blank')}>View Data</Button>
        </>)}
        <Text fontSize="lg" fontWeight = "bold" >Chatbot Name</Text>
        <Text fontSize="sm">The name which appears in the header of the chatbot.</Text>
        
        <Flex mt = "3">
            <Input
                value={chatbotName}
                onChange={handleInputChange}
                placeholder="My Chatbot Name"
                mr={2}
                bgColor = "white"
                h = "50px"
            />
            <Button h = "50px" w = "100px" colorScheme="green" onClick={handleSaveClick}>
                Save
            </Button>
        </Flex>

        <Text mt = "3" fontSize="lg" fontWeight = "bold" >OpenAI API Key</Text>
        <Text fontSize="sm">Use the Botsheets Key and the credits included in your Botsheets plan, or switch to <Link href="https://support.botsheets.com/docs/openai-key" isExternal color='#1772cc'>your own OpenAI Key</Link> and track usage through the <Link href = "https://platform.openai.com/api-keys" isExternal color = "#1772cc" >OpenAI dashboard.</Link>. With credits, 1 Google Sheet query equals 10 credits. Every other message equals 1 credit.</Text>

        <Flex mt="3">
            <Flex flexDir="column" w = "100%" mr = "2">
                <Select 
                    value={keyType}
                    onChange={handleSelectChange} 
                    mr={2} 
                    h="50px" 
                    bgColor="white">
                    <option value="botsheets">Botsheets Key</option>
                    <option value="customOpenAI">My OpenAI Key</option>
                </Select>

                {keyType === "customOpenAI" && (
                    <>
                    <Text mt={2} fontSize="sm">
                    <Link href="https://support.botsheets.com/docs/openai-key" isExternal color='#1772cc'>Add your own OpenAI Key</Link> and give your chatbot unlimited messaging. If your region or your OpenAI account does not include access to GPT-4o, you can use the Botsheets Key.
                    </Text>
                    <Text mt={2} fontSize="sm" fontWeight = "bold" color = "red">
                    Make sure your Open AI account has access to GPT-4o. This is a requirement for Botsheets to work with your own API Key.
                    </Text>
                    <Input
                    mt = "5"
                    value={openAIKey}
                    onChange={handleOpenAIKeyChange}
                    placeholder="sk-..."
                    mr={2}
                    bgColor="white"
                    h="50px"
                    />
                    </>
                )}
            </Flex>

            <Button 
                h="50px" 
                w="100px" 
                colorScheme="green" 
                onClick={handleSaveClick} 
                isDisabled={!openAIKey && keyType === "customOpenAI"}>
                Save
            </Button>
        </Flex>


        <Text mt = "3" fontSize="lg" fontWeight = "bold" >Chat Domain</Text>
        <Text fontSize="sm">Botsheets.com is the default URL for chat widgets, page embeds, and direct links. Use our domain or add your own custom URL. <Link href="https://support.botsheets.com/docs/custom-domains" isExternal color='#1772cc'>Learn more</Link>.</Text>
        
        <Flex mt = "3">
        <Flex flexDir="column" w = "100%" mr = "2">
        <Select 
            mb = {2}
            value={domainType}
            onChange={(e) => setDomainType(e.target.value)} 
            mr={2} 
            h="50px" 
            bgColor="white">
            <option value="botsheets">botsheets.com</option>
            <option value="customDomain">Custom Domain</option>
        </Select>

            {domainType === "customDomain" && (
                <>
                    <Text my = "2" fontSize="md" fontWeight = "bold">Current Domain: {data.cnameDomain ? data.cnameDomain : 'botsheets.com'}
                        {data.cnameDomain ? <span
                            style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer', marginLeft: '8px' }}
                            onClick={() => onOpenDNS()}>
                            (Click to Verify DNS or Delete Domain)
                        </span> : null}
                    </Text>

                    <CustomDomainDropdown handleNewDomainClick = {handleNewDomainClick} user = {user} onOpenDomain = {onOpenDomain} id = {id} />   
                </>
            )}

        </Flex>

        <Button 
            h="50px" 
            w="100px" 
            colorScheme="green" 
            onClick={handleSaveDomainClick} >
            Save
        </Button>
        </Flex>
        

        <Text mt = "3" fontSize="lg" fontWeight = "bold" >Danger Zone</Text>
        <Text fontSize="sm">Deleting this chatbot will not delete your Google Sheet and any data you've captured in it already.</Text>
        <Flex mt = "3">
            <Button onClick = {() => setIsDeleteModalOpen(true)} w = "100%" borderWidth = "2px" colorScheme="red" variant = "outline">
                Delete Chatbot
            </Button>
        </Flex>

        <ConfirmDeleteModal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            onDelete={handleDelete}
            handleDeleteClick = {handleDeleteClick}
      />

        <UpgradeModal text = {upgradeText} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
        <CustomDomainModal text = {upgradeText} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpenDomain} onClose = {onCloseDomain}/>
        <VerifyDNSModal url = {data.cnameDomain} text = {upgradeText} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpenDNS} onClose = {onCloseDNS}/>
    </Box>
  )
}

export default ManageGeneral