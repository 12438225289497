import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  Text,
  ModalFooter,
  Code
} from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';

const WebhookModal = ({ hookURL, isOpen, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { user } = useAuthContext();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputValue(hookURL);
  }, [hookURL])

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSaveClick = () => {

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/bot/webhook/manage/${id}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({webhookUrl: inputValue, botId: id})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response
    }).then(data => {
      
      alert('Webhook saved successfully.');
      setLoading(false);
      onClose();


    }).catch(error => {
        alert('Failed to save webhook. Please check the URL and try again.');
        setLoading(false);
    })
  };

  const handleTestClick = () => {

    setLoading(true);

    if (!inputValue) {
        alert('Please provide a URL.');
        setLoading(false);
        return;
    }

    if (!inputValue.startsWith('http://') && !inputValue.startsWith('https://')) {
        alert('Please provide a valid URL.');
        setLoading(false);
        return;
    }
  

    fetch(`${process.env.REACT_APP_API_URL}/bot/webhook/test`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({webhookUrl: inputValue, botId: id})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response
    }).then(data => {
      
      alert('Webhook successfully sent. Please check your endpoint for the mock object.');
      setLoading(false);


    }).catch(error => {
        alert('Failed to send webhook. Please check the URL and try again.');
        setLoading(false);
    })
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Webhook</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text mb={2} fontSize = "sm">
              A Webhook gives you raw data from chat conversations. Provide a destination URL known as an Endpoint to send data.
            </Text>
            <Text mb={2} fontSize = "sm">
              Botsheets uses POST requests to deliver JSON to your specified endpoint, including a unique identifier for the chat session, the chatbot's name, and data collected.
            </Text>
            <Text mb={2} fontSize = "sm">
                <b>We add a 15-minute delay in processing requests to ensure complete data capture and reduce the chances of incomplete data caused by extended chat sessions.</b>
            </Text>
            <Text mb={2} fontSize = "sm">
              Test your webhook and we'll simulate the process by sending sample data to your Endpoint URL. It's up to you to confirm your receiving endpoint captures the data we send.
            </Text>
            <Text color = "#000000" fontSize = "lg" fontWeight = "semibold" >Example Webhook:</Text>
            <Code p = "4" display="block" whiteSpace = "pre" children={` { 
    "uuid": "1eo209-1dj...",
    "bot_name": "My Sales Bot",
    "data": {"name": "Jim", "budget": "400"}
 }`} />

            <FormControl>
            <Text color = "#000000" fontSize = "lg" fontWeight = "semibold" >Your Endpoint URL </Text>
                <Text fontSize = "sm" mb = "3">Leave blank to disable.</Text>
                <Input
                    placeholder="https://"
                    value={inputValue}
                    onChange={handleInputChange}
                    backgroundColor = "gray.100"
                    borderColor = "gray.300"
                    />
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
            <Button isLoading = {loading} colorScheme='green' variant = "ghost" mr={3} onClick={handleTestClick}>
              Test Webhook
            </Button>
            <Button isLoading = {loading} colorScheme='green' onClick={handleSaveClick}>
              Save Webhook
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WebhookModal;