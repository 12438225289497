const planPermissions = {
    free: 0,
    lite: 1,
    ASTier1: 1,
    pro: 2,
    ASTier2: 2,
    platinum: 3,
    ASTier3: 3,
    ASTier4: 4
};

export const validUserPlan = (requiredPermission, userPlan) => {
    const userPermission = planPermissions[userPlan] || 0;
    console.log("Permission Check: ", userPermission, requiredPermission, userPermission >= requiredPermission ? "Allowed" : "Denied")

    return userPermission >= requiredPermission;
}