import { Button, Box, Card, CardBody, CardFooter, CardHeader, Heading, SimpleGrid, Text, Flex } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useBotsContext } from "../hooks/useBotsContext"
import { useAuthContext } from "../hooks/useAuthContext"
import MainStepper from '../components/stepper/MainStepper'
import CreateBotForm from '../components/CreateBotForm'

const CreateBot = () => {

  const {bots, dispatch} = useBotsContext();
  const {user} = useAuthContext();
  const [businessDescription, setBusinessDescription] = useState('');

  useEffect(() => {
    const fetchBots = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/bot`, {
        headers: {'Authorization': `Bearer ${user.token}`},
      })
      const json = await response.json()

      if (response.ok) {
        dispatch({type: 'SET_BOTS', payload: json})
      }
    }

    if (user) {
      fetchBots()
      //console.log(bots)
    }
  }, [dispatch, user])

  const handleUpdateTitle = () => {
    console.log('called')
    const storedData = sessionStorage.getItem('storedBot');

    if (storedData) {
      // If data exists in sessionStorage, parse it back to an object
      const parsedObject = JSON.parse(storedData);
      const business_category = parsedObject.business_category;

      const words = business_category.split(' ');

      // Capitalize the first letter of each word
      const capitalizedWords = words.map(word => {
        // Make sure the word is not empty
        if (word.length === 0) {
          return '';
        }
        // Capitalize the first letter and keep the rest of the word in lowercase
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });

      // Join the capitalized words back into a single string
      const resultString = capitalizedWords.join(' ');

      setBusinessDescription(resultString);


    }

  }

  return (
    <Box p = "5">
      <Heading fontSize = "xl">Create a Discovery Chatbot</Heading>
      <MainStepper handleUpdateTitle = {handleUpdateTitle}/>
    </Box>
  )
}

export default CreateBot