import { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Select,
    Checkbox,
    Textarea,
    Button,
    HStack,
    Spacer,
    Text,
    useDisclosure
  } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import LoadingBox from '../common/LoadingBox';
import { useNavigate, Link } from 'react-router-dom';
import CreatingBotModal from './components/CreatingBotModal';
import ConfettiExplosion from 'react-confetti-explosion';


const Step5 = ({goToNext, goToPrevious}) => {

    const [storedBot, setStoredBot] = useState(null);
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isExploding, setIsExploding] = useState(false);

    const [formData, setFormData] = useState({
        chatbotName: 'My Chatbot' + Math.floor(Math.random() * 1000),
        welcomeMessage: 'Hi, I’m an AI assistant ready to help you.',
        leadingQuestion: 'No',
        enforceEmailCollection: false,
        emailCollectionReason: '',
        enforceDataValidation: false,
        enforceDataTypes: [],
        crossReferenceData: false,
        tone: 'lighthearted',
        customWelcomeMessage: '',
      });

    const [leadingChoices, setLeadingChoices] = useState([]);

    useEffect(() => {
        // Retrieve the object from sessionStorage
        const storedData = sessionStorage.getItem('storedBot');
        const step5 = sessionStorage.getItem('step5');
        const step4 = sessionStorage.getItem('step4_selected');

        if (storedData) {
          
          // If data exists in sessionStorage, parse it back to an object
          const parsedObject = JSON.parse(storedData);
          console.log('storedData', parsedObject)
          setStoredBot(parsedObject);
          var newFormData = {}

          if (step5) {
            const parsedObject = JSON.parse(step5);
            newFormData = parsedObject;
            setFormData(parsedObject);

            console.log('step5', parsedObject)
            console.log(formData)
          }

          if (step4) {
            const parsedObject = JSON.parse(step4);
            setLeadingChoices(parsedObject);
          }

          setLoading(false);
        }
    }, []);


    const handleDoneClick = () => {
        setButtonLoading(true);
        setLoading(true);


        if (!formData) {
          alert('Error saving data. Please try again.');
          setButtonLoading(false);
          return;
        }

        const updatedBot = {
            ...storedBot,
            rules: formData,
        }

        sessionStorage.setItem('storedBot', JSON.stringify(updatedBot));
        setStoredBot(updatedBot);

        // validate form data
        if (!formData.chatbotName || !formData.welcomeMessage) {
            alert('Please fill out all fields');
            setButtonLoading(false);
            return;
        }

        if (formData.leadingQuestion === 'Yes' && !formData.additionalWelcomeMessage) {
            alert('You marked that you want to ask a leading question, but you did not provide one. Please fill out all fields');
            setButtonLoading(false);
            return;
        }

        if (formData.additionalWelcomeMessage === "CUSTOM") {
          formData.additionalWelcomeMessage = formData.customWelcomeMessage;
        }
        

        fetch(`${process.env.REACT_APP_API_URL}/create`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
          body: JSON.stringify(updatedBot)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
          console.log('valid')

          console.log(data.bot_id);

          setButtonLoading(false);
          sessionStorage.removeItem('storedBot');
          sessionStorage.removeItem('step2');
          sessionStorage.removeItem('step3_all');
          sessionStorage.removeItem('step3_selected');
          sessionStorage.removeItem('step4_all');
          sessionStorage.removeItem('step4_selected');
          sessionStorage.removeItem('step5');

          window.location.href = `/manage/${data.bot_id}?source=create`;

        }).catch(error => {
          console.log(error);
          setLoading(false);
          alert('An error occured, please try again. Check your account limits and upgrade if necessary.');
        })
    
      };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handlePreviousClick = () => {

      sessionStorage.setItem('step5', JSON.stringify(formData));

      goToPrevious();

    };

    const handleContinueClick = () => {
      sessionStorage.setItem('step5', JSON.stringify(formData));
      
      goToNext();
    }


    if (loading) {
        return <LoadingBox />;
    }

    return (
        <Box>
          <Box display = 'flex' flexDir = "column">
                <Text color = "#EC6060" fontSize = "xl" fontWeight = "semibold">Set rules of engagement for your chatbot</Text>
                <Text mb = "5" color = "gray.500" fontSize="md">Set some basic rules for now. You can add more advanced rules later with our Prompt Editor.</Text>
            </Box>
            <Box display = 'flex' flexDir = "column">

                <form>

      <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Text color = "#000000" fontSize = "lg" fontWeight = "semibold">Customize The Greeting Message</Text>
          <Text mb = "5" color = "gray.500" fontSize="md">Edit the first message a user will see when they engage with your chatbot.</Text>
          </FormLabel>
          <Textarea
            name="welcomeMessage"
            value={formData.welcomeMessage}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          />
        </FormControl>
      </Box>

      <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Text color = "#000000" fontSize = "lg" fontWeight = "semibold">Add a Leading Question?</Text>
          <Text mb = "5" color = "gray.500" fontSize="md">Your chatbot will ask questions to collect. A leading question is a great conversation starter and will appear immediately after the Greeting Message.</Text>
          </FormLabel>
          <Select
            name="leadingQuestion"
            value={formData.leadingQuestion}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Select>
        </FormControl>
      </Box>

      {formData.leadingQuestion === 'Yes' && (<Box mb={4}>
        <FormControl>
          <FormLabel>
          <Text mb = "5" color = "gray.500" fontSize="md">Select a leading question or add your own custom leading question.</Text>
          </FormLabel>
          <Select
            name="additionalWelcomeMessage"
            value={formData.additionalWelcomeMessage}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value = "">Select an option</option>
            {leadingChoices.filter((choice) => choice.example_question).map((choice) => (
              <option value={choice.example_question}>{choice.example_question}</option>
            ))}
            <option value="CUSTOM">Custom</option>
          </Select>
        </FormControl>
      </Box>)}

      {formData.leadingQuestion === 'Yes' && formData.additionalWelcomeMessage === "CUSTOM" && (
        <Box mb={4}>
          <FormControl>
            <FormLabel>
            <Text mb = "5" color = "gray.500" fontSize="md">Enter your custom leading question.</Text>
            </FormLabel>
            <Textarea
              name="customWelcomeMessage"
              value={formData.customWelcomeMessage}
              onChange={handleInputChange}
              backgroundColor = "gray.100"
              borderColor = "gray.300"
            />
          </FormControl>
        </Box>
      )}

      <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Text color = "#000000" fontSize = "lg" fontWeight = "semibold">Personality</Text>
          <Text mb = "5" color = "gray.500" fontSize="md">Your chatbot is a voice for your brand. A lighthearted personality is creative and engaging. Witty or Sassy adds extra personality with the most creative responses.</Text>
          </FormLabel>
          <Select
            name="tone"
            value={formData.tone}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          >
            <option value="lighthearted">Lighthearted</option>
            <option value="Casual">Casual</option>
            <option value="Witty">Witty</option>
            <option value="Sassy">Sassy</option>
            <option value="Energetic">Energetic</option>
            <option value="Professional">Professional</option>
            <option value="Concise">Concise</option>
          </Select>
        </FormControl>
      </Box>


      {/* <Box mb={4}>
        <FormLabel>Enforce Email Collection</FormLabel>
        <Checkbox
          name="enforceEmailCollection"
          isChecked={formData.enforceEmailCollection}
          onChange={handleInputChange}
        >
          Require the user provide an email address before responding to any
          message
        </Checkbox>
      </Box>

      {formData.enforceEmailCollection && (
        <Box mb={4}>
          <FormControl>
            <FormLabel>Email Collection Reason</FormLabel>
            <Input
              type="text"
              name="emailCollectionReason"
              value={formData.emailCollectionReason}
              onChange={handleInputChange}
            />
          </FormControl>
        </Box>
      )}

      <Box mb={4}>
        <FormLabel>Enforce Data Validation</FormLabel>
        <Checkbox
          name="enforceDataValidation"
          isChecked={formData.enforceDataValidation}
          onChange={handleInputChange}
        >
          Ensure that all numbers, dates, email, and other data types are in the
          format you expect
        </Checkbox>
      </Box>

      <Box mb={4}>
        <FormLabel>Cross-reference Data</FormLabel>
        <Checkbox
          name="crossReferenceData"
          isChecked={formData.crossReferenceData}
          onChange={handleInputChange}
        >
          Cross-reference any data you collect with any data already stored and
          confirm with the user if they want to update their response.
        </Checkbox>
      </Box> */}
    </form>

            </Box>

            <HStack mt = "5">
                <Button mr = "3" colorScheme = "green" onClick={handlePreviousClick}>Previous</Button>
                <Spacer />
                <Box>
                {isExploding && <ConfettiExplosion duration="3000"/>}
                <Button colorScheme = "green" onClick={handleContinueClick}>Continue</Button>
                </Box>
                
            </HStack>
            
            {/* <pre>{JSON.stringify(formData)}</pre> */}
           
        </Box>
        
    )
}

export default Step5