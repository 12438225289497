import { TableContainer, FormControl, FormLabel, Textarea, Table, useDisclosure, Thead, Tr, Th, Link, Tbody, Td, Button, TbodyButton, Card, Icon, CardBody, IconButton, HStack, Spacer, Grid, GridItem, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast} from '@chakra-ui/react'
import DeleteBotButton from '../../DeleteBotButton'
import LoadingBox from '../../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../../hooks/usePublicFetch';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { FaTrash } from 'react-icons/fa'
import PDFImportModal from './components/PDFImportModal';
import URLImportModal from './components/URLImportModal';
import SheetsImportModal from './components/SheetsImportModal';
import SheetSettingsModal from './components/SheetSettingsModal';

const DataDocs = (props) => {

    const { isOpen: isPDFOpen, onOpen: onPDFOpen, onClose: onPDFClose } = useDisclosure()
    const { isOpen: isURLOpen, onOpen: onURLOpen, onClose: onURLClose } = useDisclosure()
    const { isOpen: isSheetsOpen, onOpen: onSheetsOpen, onClose: onSheetsClose } = useDisclosure()
    const { isOpen: isSheetSettingsOpen, onOpen: onSheetSettingsOpen, onClose: onSheetSettingsClose } = useDisclosure()

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [files, setFiles] = useState([]);
    const [GSheets, setGSheets] = useState([]);


    const handleDeleteClick = (pdfUuid) => {

        console.log(files)

        fetch(`${process.env.REACT_APP_API_URL}/create/manage/delete/` + id, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'pdfUuid': pdfUuid})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            toast({
                title: `Successfully Deleted`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })

            props.updateBotData(data);
        }
        ).catch(error => {
            console.log(error)
        })
    }

    const handleSheetsDeleteClick = (sheet) => {

        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'PATCH',
            headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({'GSheets': GSheets.filter(item => item !== sheet)})
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            toast({
                title: `Successfully Deleted`,
                isClosable: true,
                status: "success",
                duration: 1800,
              })

            props.updateBotData(data);
        }
        ).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (data) {
            setFiles(data.files);
        }

        if (GSheets) {
            setGSheets(data.GSheets);
        }

    }, [data, GSheets])

    if (isPending) {
    return <div>Loading Chatbot Info...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

    console.log('data', data)

  return (
    <Box mt = "1" bgColor = "white" w = "100%" borderRadius = "5" p = "3">
        <Heading size='md' fontWeight = "bold" color = "#38a169">Google Sheets</Heading>
        <Text mb = "5" color = "gray.500" fontSize="sm"><Link href="https://support.botsheets.com/docs/data-sources" isExternal color='#1772cc'>Connect datasets</Link> stored in Google Sheets. Connect one at a time and <Link href="https://support.botsheets.com/docs/datasets" isExternal color='#1772cc'>see example datasets</Link>. We automatically sync up changes you make to your data with AI so you only need to connect this datasource once.</Text>
        <TableContainer mt = "5" >
            <Table variant='simple'>
                <Thead>
                <Tr>
                    <Th>Datasource</Th>
                    <Th>Data Type</Th>
                    <Th>Delete</Th>
                </Tr>
                </Thead>
                <Tbody>
                {GSheets.map((sheet, index) => (
                <Tr key = {index}>
                    <Td whiteSpace="normal"><a href={sheet.url} target="_blank"><Text color = "#1772cc" fontSize="md">{sheet.desc}</Text></a></Td>
                    <Td whiteSpace="normal">Google Sheets</Td>
                    <Td whiteSpace="normal"><Box width="10%" padding="5px" textAlign="center">
                            <IconButton
                            onClick = {() => handleSheetsDeleteClick(sheet)}
                            aria-label="Delete"
                            icon={<FaTrash />}
                            variant="ghost"
                            colorScheme="red"
                            />
                        </Box>
                    </Td>
                </Tr>
                ))}
                </Tbody>
            </Table>
        </TableContainer>

        <Button onClick={onSheetsOpen} mt = "5" colorScheme = "blue" variant = "outline" >Connect Google Sheet</Button>
        <Button onClick={onSheetSettingsOpen} ml = "5" mt = "5" colorScheme = "blue" variant = "outline">Sheet Search Settings</Button>
        
        <Heading mt = "5" size='md' fontWeight = "bold" color = "#38a169">Other Documents</Heading>
        <Text mb = "5" color = "gray.500" fontSize="sm"><Link href="https://support.botsheets.com/docs/data-sources" isExternal color='#1772cc'>Connect webpages</Link> one at a time and we automatically sync up changes you make to your webpages with AI so you only need to connect this datasource once. Upload PDF documents to create a knowledge base so your chatbot can answer questions.</Text>
        <HStack mt = "5">
            <Button onClick={onURLOpen} mr = "3" colorScheme = "blue" variant = "outline" >Connect Webpage</Button>
            <Button onClick={onPDFOpen} mr = "3" colorScheme = "blue" variant = "outline" >Upload PDF</Button>
        </HStack>
        <TableContainer mt="4">
  <Table variant='simple'>
    <Thead>
      <Tr>
        <Th>Datasource</Th>
        <Th>Data Type</Th>
        <Th>Characters</Th>
        <Th>Delete</Th>
      </Tr>
    </Thead>
    <Tbody>
      {files.map((datasource, index) => (
        <Tr key={index}>
          <Td whiteSpace="normal">{datasource.name}</Td>
          <Td whiteSpace="normal">{datasource.type}</Td>
          <Td whiteSpace="normal">{datasource.charCount}</Td>
          <Td>
            <Box width="10%" padding="5px" textAlign="center">
              <IconButton
                onClick={() => handleDeleteClick(datasource.pdfUUid)}
                aria-label="Delete"
                icon={<FaTrash />}
                variant="ghost"
                colorScheme="red"
              />
            </Box>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
</TableContainer>

        
        

        <PDFImportModal data = {props.data} updateBotData = {props.updateBotData} isOpen={isPDFOpen} onClose={onPDFClose} />
        <URLImportModal data = {props.data} updateBotData = {props.updateBotData} isOpen={isURLOpen} onClose={onURLClose} />
        <SheetsImportModal data = {props.data} updateBotData = {props.updateBotData} isOpen={isSheetsOpen} onClose={onSheetsClose} />
        <SheetSettingsModal data = {props.data} updateBotData = {props.updateBotData} isOpen={isSheetSettingsOpen} onClose={onSheetSettingsClose} />
    </Box>
  )
}

export default DataDocs