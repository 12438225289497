import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  Text,
  ModalFooter,
  Code
} from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';
import { set } from 'date-fns';

const APIModal = ({ isOpen, onClose }) => {
  const [secretKey, setSecretKey] = useState('sk-************************');
  const { user } = useAuthContext();
  const {id} = useParams();

  const [loading, setLoading] = useState(false);


  const handleSaveClick = () => {

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/userinfo/key/generate/`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({})
    })
    .then(response => {
        console.log('response', response)
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return response.json()
    }).then(data => {
      
      alert('Your API key was successfully generated. Copy this key and do not share it with anyone.');
      setLoading(false);
      setSecretKey(data.apiKey);


    }).catch(error => {
        alert('Failed to generate key.');
        setLoading(false);
    })
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Botsheets API (Beta)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Text mb={2} fontSize = "sm">
              Programmatically handle conversations with this chatbot using our API. The API is currently in Beta and in active development. API endpoints and features are subject to change. We encourage and appreciate your input in our Discord.
            </Text>
            <Text mb={2} fontSize = "md" fontWeight = "bold" color = "blue.500" textDecoration = "underline">
            <a href="https://botsheets.readme.io/" target="_blank" rel="noreferrer">See API Documentation</a>  |  <a href="https://discord.gg/5zfQyCf6Y3" target="_blank" rel="noreferrer">Discord</a>
            </Text>
            
            <Button mt = "3" isLoading = {loading} colorScheme='green' onClick={handleSaveClick}>
              Generate New Secret Key
            </Button>
            <Text color = "#009d69" mt = "3" fontWeight = "semibold" >Secret Key:</Text>
            <Text color = "#000000" mt = "3" fontWeight = "semibold" >{secretKey} </Text>

            <Text color = "#009d69" mt = "3" fontWeight = "semibold" >Bot ID:</Text>
            <Text color = "#000000" mt = "3" fontWeight = "semibold" >{id ? id : "Error"} </Text>
            <Text color = "#808080" fontSize = "sm" mt = "3" fontWeight = "bold">Use one API Secret Key for all chatbots in this account. You can only have one active Secret Key per account. Generating a new key, even for a different bot, will invalidate all previous keys generated. Copy the Secret Key and do not share it with anyone.</Text>

          </Box>
        </ModalBody>
        <ModalFooter>
            <Button isLoading = {loading} colorScheme='green' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default APIModal;