import React, { useState } from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Link, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useAuthContext } from '../../../hooks/useAuthContext';

const AddSheetsModal = ({ isOpen, onClose, addSheetsURLSource }) => {
  // State to hold the input value
  const [googleSheetsURL, setGoogleSheetsURL] = useState('');
  const [desc, setDesc] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAuthContext();

  const handleImport = () => {
    if (!googleSheetsURL) {
      alert('Please enter a Google Sheets URL');
      return;
    }

    if (!desc) {
      alert('Please enter a description of the data in your Google Sheet. Based on your description, AI will determine if your Google Sheet is the best data source to generate a response over other document types so make sure your description is descriptive.');
      return;
    }

    // check that the url contains a spreadsheet id
    const regex = /spreadsheets\/d\/([a-zA-Z0-9-_]+)/;
    const match = googleSheetsURL.match(regex);

    if (!match) {
        alert('That is not a valid Google Sheets URL.');
        return;
    }

    setLoading(true)

    // post request with the url
    fetch(`${process.env.REACT_APP_API_URL}/create/csv`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${user.data.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        url: googleSheetsURL
      })
    })
    .then(response => {
      return response.json()
    }).then(data => {
      console.log(data)
      if (data.error) {
        alert(data.error);
        setLoading(false)
        return;
      }
      setLoading(false)
      addSheetsURLSource(googleSheetsURL, desc)
      onClose();
      setGoogleSheetsURL('')
      setDesc('')
    }).catch(error => {
      setLoading(false)
      console.log(error)
      alert('There was an Error connecting to your Google Sheet')
    })

    
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Connect Google Sheets (Beta)</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Text color="gray.600">
            Connect one or more Google Sheets for lists of data and frequently changing content. <Link href="https://support.botsheets.com/docs/connected-data" isExternal color='#1772cc'>See how it works</Link> and how to prepare a dataset. We automatically sync up changes you make to your Google Sheet data.
            </Text>


            <FormLabel mt = {4} htmlFor="google-sheets-url"><Text fontSize="md" fontWeight = "extrabold">Your Google Sheet URL</Text></FormLabel>
            <Input 
              id="google-sheets-url"
              type="text"
              value={googleSheetsURL}
              onChange={(e) => setGoogleSheetsURL(e.target.value)}
              placeholder="Paste in the URL for your Google Sheet"
              backgroundColor = "gray.100"
              borderColor = "gray.300"
            />

            <Text mt={2} fontSize="sm" fontWeight = "extrabold" color = "blue.600">
              Only the first worksheet in your spreadsheet will be connected.
            </Text>

            <FormLabel mt = {4} ><Text fontSize="md" fontWeight = "extrabold">Describe Your Data</Text></FormLabel>
            <Input 
              type="text"
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Example: A database of properties for rent"
              backgroundColor = "gray.100"
              borderColor = "gray.300"
            />

            <Text mt={2} fontSize="sm" fontWeight = "extrabold" color = "blue.600">
              Be descriptive to help AI understand this data source.
            </Text>
            
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading = {loading} colorScheme = "green" onClick={handleImport}>
            Connect Google Sheet
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSheetsModal;
