import React from "react";
import { Flex, Text, Badge, Box, IconButton } from "@chakra-ui/react";
import { MdRefresh } from 'react-icons/md';

const Header = (props) => {

  const pickTextColorBasedOnBgColorSimple = (bgColor, lightColor, darkColor) => {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 190) ?
      darkColor : lightColor;
  }

  return (
<Flex bgColor={props.widgetColor} w="100%" h="50px" align="center">
  <Flex flexDirection="column" mx="5" justify="center">
    <Text color={pickTextColorBasedOnBgColorSimple(props.widgetColor, '#FFFFFF', '#000000')} my="3" fontSize="lg" fontWeight="bold">
      {props.visibleName ? props.visibleName : "Chatbot"}
      <Badge ml="5">
        {props.badge_visibility ? "Powered by AI" : ""}
      </Badge>
    </Text>
  </Flex>
  <Box ml="auto" mr="5">
  <IconButton
  icon={<MdRefresh style={{ color: 'gray' }} />}
  aria-label="Reset"
  onClick={props.onReset}
  variant="ghost"
  style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
  _hover={{ backgroundColor: 'transparent' }}
  />
  </Box>
</Flex>
  )
};

export default Header;
