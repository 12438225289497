import { Flex, Heading, Input, Center, Box, Text, FormControl, FormLabel, Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Divider from "../components/chat/Divider";
import Footer from "../components/chat/Footer";
import Messages from "../components/chat/Messages";
import Home from "../components/chat/Home";
import Suggestions from "../components/chat/Suggestions";
import Header from "../components/chat/Header";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useNoAuthFetch } from "../hooks/useNoAuthFetch";
import MessagesAnalysis from "../components/chat/MessagesAnalysis";
import PrivacyPopup from "../components/chat/PrivacyPopup";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import ScrollableTable from "../components/chat/ScrollableTable";
import FooterTable from "../components/chat/FooterTable";

const Table = () => {
  const {id} = useParams();
  const params = new URLSearchParams(window.location.search);
  const method = params.get("method");

  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showThreeDots, setShowThreeDots] = useState(false); //for the loading animation
  const [uuid, setUuid] = useState(''); //uuid for the user
  const { data, error, isPending } = useNoAuthFetch(`${process.env.REACT_APP_API_URL}/publicbot/` + id);

  const [password, setPassword] = useState('');
  const [hideScreen, setHideScreen] = useState(true);

  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

  const [sheetData, setSheetData] = useState([]);

  const handleAcceptPrivacy = () => {
    localStorage.setItem(id + 'privacyAccepted', 'true');
    setShowPrivacyPopup(false);
  };


  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/chat/table/start/` + id, {
      })
    .then(response => {
        if (!response.ok) {
            throw new Error(response.status)
        }
        return response.json()
    })
    .then(data => {
        setSheetData(data)
        console.log(data)})
  }, [])

  const handleGenerateQuery = () => {
    fetch(`${process.env.REACT_APP_API_URL}/chat/table/generate/` + id, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({message: inputMessage})
    })
  .then(response => {
      if (!response.ok) {
          throw new Error(response.status)
      }
      return response.json()
  })
  .then(data => {
      setHistory((old) => [...old, inputMessage]);
      setSheetData(data)
      console.log(data)})
  }

  const handleUpdateQuery = () => {
    fetch(`${process.env.REACT_APP_API_URL}/chat/table/update/` + id, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({message: inputMessage, history: history})
    })
  .then(response => {
      if (!response.ok) {
          throw new Error(response.status)
      }
      return response.json()
  })
  .then(data => {
      setSheetData(data)
      console.log(data)})
  }

  if (isPending) {
    return <div></div>
  }

  if (error) {
    return <div>{ error }</div>
  }

  if (data && data.active === false) {
    return (
      <Heading>This chatbot is currently unavailable. If you are the owner, check your dashboard.</Heading>
      )
  }

  if (data && data.rules.welcomeMessage && messages.length === 0) {
    setMessages([{ from: "computer", text: data.rules.welcomeMessage }]);
    setHistory([{ role: 'assistant', content: data.rules.welcomeMessage }]);


    if (data.rules.leadingQuestion === "Yes" && data.rules.additionalWelcomeMessage) {
        setMessages((old) => [...old, { from: "computer", text: data.rules.additionalWelcomeMessage }]);
        setHistory((old) => [...old, { role: 'assistant', content: data.rules.additionalWelcomeMessage }]);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    //make a post call to the backend to check if the password is correct
    const response = await fetch(`${process.env.REACT_APP_API_URL}/publicbot/` + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password: password
      }),
    });

    //response is {"unlocked": true/false}, if true then set private to false
    const data = await response.json();

    if (data.unlocked === true) {
      setHideScreen(false);
      console.log('unlocked')
    }

  };


  if (data && data.private && hideScreen) {
    // Render password protection UI
    return (
      <Center height="100vh">
        <Box p={4} borderWidth="1px" borderRadius="md" textAlign="center">

              <Text fontSize="xl" fontWeight="bold">
                {data.name}
              </Text>
              <form onSubmit={handleSubmit}>
                <FormControl mt={4}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                  Submit
                </Button>
              </form>
        </Box>
      </Center>
  );
}

const handleSendDataAnalysisMessage = async () => {
    
  if (!inputMessage.trim().length) {
    //console.log('empty msg');
    return;
  }

  setLoading(true)
  setMessages((old) => [...old, { from: "me", text: inputMessage }]);
  setHistory((old) => [...old, { role: 'user', content: inputMessage }])
  setInputMessage("");

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/analysis/` + id, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        question: inputMessage,
        chatID: uuid,
      }),
    });
    const data = await response.json();


    const explanation = data.result.explanation; // Replace this with actual explanation logic

    if (data.result.type === 'string') {
      setMessages((old) => [...old, { from: "computer", text: data.result.data, explanation: data.result.explanation }]);
    }

    else if (data.result.type === 'dataframe') {
      setMessages((old) => [...old, { from: "dataframe", text: data.result.data, explanation: data.result.explanation }]);
    }

    else if (data.result.type === 'image') {
      setMessages((old) => [...old, { from: "image", text: data.result.data, explanation: data.result.explanation }]);
    }

    if (explanation != "" && explanation != null && data.result.data != "I had an issue querying your data. Try rephrasing your question or request.") {
      setMessages((oldMessages) => [
        ...oldMessages,
        { from: "computer", text: explanation }
      ]);
    }


    /* setSuggestions([['Need more help?'], ["Ask another question"]]); */
    setLoading(false);
    

  } catch (error) {
    setLoading(false);
    setMessages((old) => [...old, { from: "computer", text: "Hmm, I think our wires got crossed. Can you say that again?" }]);
  }
};

  const handleSendMessage = async () => {
    
    if (!inputMessage.trim().length) {
      //console.log('empty msg');
      return;
    }

    setLoading(true)
    setShowThreeDots(true);
    setMessages((old) => [...old, { from: "me", text: inputMessage }]);
    setHistory((old) => [...old, { role: 'user', content: inputMessage }])
    setInputMessage("");

    if (inputMessage.length > 505) {
      setInputMessage(inputMessage.substring(0, 505));
    }

    if (messages.length > 100) {
      setMessages((old) => [...old, { from: "computer", text: "I'm sorry, this conversations approaching its length limit, please refresh if you have additional questions!" }]);
      setLoading(false);
      setShowThreeDots(false);
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/` + id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: inputMessage,
          history,
          uuid
        }),
      });

      let accumulatedData = '';

      const contentType = response.headers.get('Content-Type');
      if (contentType.includes('text/plain')) {

        setHistory((old) => [...old, { role: 'assistant', content: '' }]);
        setMessages((old) => [...old, { from: "computer", text: '' }]);
        setShowThreeDots(false);

        // Here we start prepping for the streaming response
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        const loopRunner = true;

        while (loopRunner) {
          // Here we start reading the stream, until its done.
          const { value, done } = await reader.read();
          if (done) {
            setLoading(false);
            setShowThreeDots(false);
            break;
          }
          // create a new history and messages object
          accumulatedData += decoder.decode(value, { stream: true });
          const decodedChunk = decoder.decode(value, { stream: true });

          const transformedData = { from: 'computer', text: accumulatedData };
          const transformedHistoryData = { role: 'assistant', content: accumulatedData };
      
          setHistory(oldHistory => {
            const newHistory = oldHistory.slice(0, -1); // Remove the last message
            return [...newHistory, transformedHistoryData]; // Append the updated message
          });
      
          setMessages(oldMessages => {
            const newMessages = oldMessages.slice(0, -1); // Remove the last message
            return [...newMessages, transformedData]; // Append the updated message
          });
        }

      } else {
        const data = await response.json();

        if (data.error) {
          throw new Error(data.error);
        } else {

          const transformedData = data.map((str) => {
            if (str && str !== '') {
              return { from: 'computer', text: str };
            }
            return null; // Skip empty strings
          });
      
          const nonEmptyObjects = transformedData.filter((obj) => obj !== null);

          const transformedHistoryData = data.map((str) => {
            if (str && str !== '') {
              return { role: 'assistant', content: str };
            }
            return null; // Skip empty strings
          });
      
          const nonEmptyHistoryObjects = transformedHistoryData.filter((obj) => obj !== null);

          setHistory((old) => [...old, ...nonEmptyHistoryObjects]);

          setMessages((old) => [...old, ...nonEmptyObjects]);
        }

      }
      /* setSuggestions([['Need more help?'], ["Ask another question"]]); */
      setLoading(false);
      setShowThreeDots(false);
      

    } catch (error) {
      console.log('error', error)
      setLoading(false);
      setShowThreeDots(false);
      setMessages((old) => [...old, { from: "computer", text: "Hmm, I think our wires got crossed. Can you say that again?" }]);
    }
  };

  const resetConversation = () => {
    setMessages([]);
    setHistory([]);
    setInputMessage("");
    setSuggestions(null);
    setUuid(uuidv4());

    if (data && data.rules.welcomeMessage && messages.length === 0) {
        setMessages([{ from: "computer", text: data.rules.welcomeMessage }]);
        setHistory([{ role: 'assistant', content: data.rules.welcomeMessage }]);
    
    
        if (data.rules.additionalWelcomeMessage) {
            setMessages((old) => [...old, { from: "computer", text: data.rules.additionalWelcomeMessage }]);
            setHistory((old) => [...old, { role: 'assistant', content: data.rules.additionalWelcomeMessage }]);
        }
      }

    localStorage.removeItem(id);
  }



  return (
    <Flex w="100%" h="100vh" justify="center" align="center" >
      <Flex w="100%" h="100%" flexDir="column" bgColor = {data.rules.backgroundColor ? data.rules.backgroundColor : undefined}>
        {showPrivacyPopup && <PrivacyPopup isOpen={showPrivacyPopup} onClose={handleAcceptPrivacy} bodyText = {data.rules.privacyMessage} method={method}/>}
        {method != "widget" && <Header widgetColor = {data.widgetColor} visibleName = {data.title} badge_visibility = {data.badge_visibility}  onReset={resetConversation} />}
        {/* {data.type == "analysis" ? <MessagesAnalysis loading = {loading} messages={messages} bot_id = {id} onExplainResult={handleExplainResult} botMessageColor={data.rules.botMessageColor} userMessageColor={data.rules.userMessageColor}/> : <Messages showThreeDots = {showThreeDots} loading = {loading} messages={messages} bot_id = {id} botMessageColor={data.rules.botMessageColor} userMessageColor={data.rules.userMessageColor} backgroundColor={data.rules.backgroundColor} /> } */}
        <ScrollableTable data = {sheetData} />
        <FooterTable
          inputMessage={inputMessage}
          handleGenerateQuery={handleGenerateQuery}
          handleUpdateQuery={handleUpdateQuery}
          setInputMessage={setInputMessage}
          handleSendMessage={data.type == "analysis" ? handleSendDataAnalysisMessage : handleSendMessage}
          loading={loading}
          powered_by = {data.powered_by}
          customTypeMessage= {data.customTypeMessage}
          backgroundColor={data.rules.backgroundColor}
        />
      </Flex>
    </Flex>
  );
};

export default Table;
