import LoadingBox from "../components/common/LoadingBox";
import React, { useState, useEffect } from "react";
import { useAuthContext } from '../hooks/useAuthContext'
import SidebarWithHeader from "../components/SidebarWithHeader";
import { Center, Box, Heading, Text } from "@chakra-ui/react"


export const ProtectedRoute = ({ active, page, component: Component }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { dispatch } = useAuthContext()
  const [down, setDown] = useState(false)

  useEffect(() => {

    console.log('Protected Route')
    // Implement your logic to check for the token in the URL and authenticate the user.
    // You can parse the URL and extract the token using JavaScript or a library like query-string.
    // Get the value of the 'token' parameter from the URL
    const urlSearchParams = new URLSearchParams(window.location.search);
    
    var token = localStorage.getItem("token")

    if (urlSearchParams.get("token")) {
        token = urlSearchParams.get("token"); 
    }

    console.log(
        'token: ', token
    )

    if (token) {
        console.log('token exists')
        //authenticate token here:
        fetch(`${process.env.REACT_APP_API_URL}/userinfo`, {
            headers: {'Authorization': `Bearer ${token}`},
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        })
        .then(data => {
            console.log(data)
            data.token = token;
            dispatch({ type: 'LOGIN', payload: { data } })
            localStorage.setItem("token", token);
            setIsAuthenticated(true);
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error)
            setIsAuthenticated(false);
            setIsLoading(false);
        })
    } else {
        setIsAuthenticated(false);
        setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <LoadingBox />;
  }
  

  if (!isLoading && !isAuthenticated) {
    
    fetch(`${process.env.REACT_APP_API_URL}/chat/checklive`)
      .then(response => {
        if (response.ok) {
            window.location.replace("https://proxy.botsheets.com/api/login/google");
        } else {
            setDown(true)
        }})

        return (
          (down && 
          <Center height="100vh">
          <Box textAlign="center">
            <Heading fontSize="2xl" mb={4}>
              Site Down for Maintenance
            </Heading>
            <Text fontSize="lg" color="gray.600">
              We're sorry for the inconvenience, but Botsheets is currently undergoing maintenance. Please check back later.
            </Text>
          </Box>
        </Center>
          )
      )
  }

    return <SidebarWithHeader page = {page} active = {active} component = {Component} />;

};

export default ProtectedRoute;