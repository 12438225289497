
export const freeOptions = [
    { id: 1, desc: '1 Chatbot' },
    { id: 2, desc: '30 Message Credits Included'},
    { id: 3, desc: '100,000 Document Character Limit'},
    { id: 4, desc: 'Write 10 Sheet Rows'},
    { id: 5, desc: '30MB File Upload Limit'},
    { id: 6, desc: 'Theme Customization'},
    { id: 7, desc: 'Community Support'},
]

export const freeNonOptions = [
    { id: 1, desc: 'Remove Botsheets Branding From Chat' },
    { id: 2, desc: 'Prompt Editor'},
    { id: 3, desc: 'Chat Transcripts'},
    { id: 4, desc: 'GPT-4o'},
    { id: 5, desc: 'Use Your Own OpenAI Key'},
    { id: 6, desc: 'API and Webhooks'},
    { id: 7, desc: 'Custom Domain For Chatbots'},
]

export const liteOptions = [
    { id: 1, desc: '2 Chatbots' },
    { id: 2, desc: '500 Message Credits Included'},
    { id: 3, desc: '500,000 Document Character Limit'},
    { id: 4, desc: 'Write 1000 Sheet Rows'},
    { id: 5, desc: '24 hour data sync'},
    { id: 6, desc: '30MB File Upload Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '3 Days of Chat Transcripts'},
    { id: 10, desc: 'Direct Support'},
]

export const liteNonOptions = [
    { id: 1, desc: 'Remove Botsheets Branding From Chat' },
    { id: 2, desc: 'GPT-4o'},
    { id: 3, desc: 'Use Your Own OpenAI Key'},
    { id: 4, desc: 'API and Webhooks'},
    { id: 5, desc: 'Custom Domain For Chatbots'},
]

export const proOptions = [
    { id: 1, desc: '5 Chatbots' },
    { id: 2, desc: '2,000 Message Credits Included'},
    { id: 3, desc: '10,000,000 Document Character Limit'},
    { id: 4, desc: 'Write 25,000 Sheet Rows'},
    { id: 5, desc: '6 hour data sync'},
    { id: 6, desc: '30MB File Upload Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '7 Days of Chat Transcripts'},
    { id: 10, desc: 'Remove Botsheets Branding From Chat'},
    { id: 11, desc: 'Direct Support'},
    { id: 12, desc: 'GPT-4o'},
    { id: 13, desc: 'Use Your Own OpenAI Key'},
    { id: 14, desc: 'API and Webhooks'},
]

export const proNonOptions = [
    { id: 1, desc: 'Custom Domain For Chatbots'},
]

export const platinumOptions = [
    { id: 1, desc: 'Unlimited Chatbots' },
    { id: 2, desc: '10,000 Message Credits Included'},
    { id: 3, desc: 'Unlimited Document Character Limit'},
    { id: 4, desc: 'Write Unlimited Sheet Rows'},
    { id: 5, desc: '1 hour data sync'},
    { id: 6, desc: '30MB File Upload Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '30 Days of Chat Transcripts'},
    { id: 10, desc: 'Remove Botsheets Branding From Chat'},
    { id: 11, desc: 'Priority Support'},
    { id: 12, desc: 'GPT-4o'},
    { id: 13, desc: 'Increased Usage Limits'},
    { id: 14, desc: 'Use Your Own OpenAI Key'},
    { id: 15, desc: 'API and Webhooks'},
    { id: 16, desc: 'Custom Domain For Chatbots'},
]

export const ASTier1Options = [
    { id: 1, desc: '2 Chatbots' },
    { id: 2, desc: '500 Monthly Message Credits Included'},
    { id: 3, desc: '500,000 Document Character Limit'},
    { id: 4, desc: 'Write 1000 Sheet Rows'},
    { id: 5, desc: '24 hour data sync'},
    { id: 6, desc: '30MB File Upload Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '3 Days of Chat Transcripts'},
    { id: 10, desc: 'Direct Support'},
]

export const ASTier1NonOptions = [
    { id: 1, desc: 'Remove Botsheets Branding From Chat' },
    { id: 2, desc: 'GPT-4o'},
    { id: 3, desc: 'Use Your Own OpenAI Key'},
    { id: 4, desc: 'API and Webhooks'},
    { id: 5, desc: 'Custom Domain For Chatbots'},
]

export const ASTier2Options = [
    { id: 1, desc: '5 Chatbots' },
    { id: 2, desc: '2000 Monthly Message Credits Included'},
    { id: 3, desc: '10,000,000 Document Character Limit'},
    { id: 4, desc: 'Write 25,000 Sheet Rows'},
    { id: 5, desc: '6 hour data sync'},
    { id: 6, desc: '30MB File Size Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '7 Days Transcript History'},
    { id: 10, desc: 'GPT-4o'},
    { id: 11, desc: 'Direct Support'},
    { id: 12, desc: 'API and Webhooks'},
    { id: 13, desc: 'Use Your Own OpenAI Key'},
]

export const ASTier2NonOptions = [
    { id: 1, desc: 'Remove Botsheets Branding From Chat' },
    { id: 2, desc: 'Custom Domain For Chatbots'},
]

export const ASTier3Options = [
    { id: 1, desc: 'Unlimited Chatbots' },
    { id: 2, desc: '10,000 Monthly Message Credits Included'},
    { id: 3, desc: 'Unlimited Document Character Limit'},
    { id: 4, desc: 'Write Unlimited Sheet Rows'},
    { id: 5, desc: '1 hour data sync'},
    { id: 6, desc: '30MB File Upload Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '30 Days Transcript History'},
    { id: 10, desc: 'Direct Support'},
    { id: 11, desc: 'Remove Botsheets Branding From Chat'},
    { id: 12, desc: 'GPT-4o'},
    { id: 13, desc: 'Use Your Own OpenAI Key'},
    { id: 14, desc: 'API and Webhooks'},
    { id: 15, desc: 'Custom Domain For Chatbots'},
]

export const ASTier3NonOptions = [
]

export const ASTier4Options = [
    { id: 1, desc: 'Unlimited Chatbots' },
    { id: 2, desc: 'Unlimited Message Credits Included'},
    { id: 3, desc: 'Unlimited Character Limit'},
    { id: 4, desc: 'Write Unlimited Sheet Rows'},
    { id: 5, desc: '1 hour data sync'},
    { id: 6, desc: '30MB File Upload Limit'},
    { id: 7, desc: 'Theme Customization'},
    { id: 8, desc: 'Prompt Editor'},
    { id: 9, desc: '30 Days Transcript History'},
    { id: 10, desc: 'Direct Support'},
    { id: 11, desc: 'Remove Botsheets Branding'},
    { id: 12, desc: 'Use Your Own OpenAI Key'},
    { id: 13, desc: 'API and Webhooks'},
    { id: 14, desc: 'Custom Domain For Chatbots'},
]

export const ASTier4NonOptions = [
]