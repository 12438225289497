import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import { ProtectedRoute } from './auth/ProtectedRoute';

// pages
import Chatbots from './pages/Chatbots'
import CreateBot from './pages/CreateBot'
import ManageBotWithSidebar from './pages/ManageBotWithSidebar';
import Chat from './pages/Chat'
import LTD from './pages/LTD'
import ManagePreview from './components/ManagePreview';
import ManageSheet from './components/ManageSheet';
import ManageEmbed from './components/ManageEmbed';
import ManageAnalysisTest from './components/ManageAnalysisTest';
import Table from './pages/Table';
import PaginatedTable from './pages/PaginatedTable';

import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {

  const { user, isLoading } = useAuthContext();

  if(isLoading) return <div></div>

  return (
    <div className="App">   
        <div className="pages">
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route 
              path="/" 
              element={
                <ProtectedRoute page = "dashboard" component = {Chatbots} />
              }
            />
            <Route
              path="/chatbots"
              element={user ? <Chatbots /> : <Navigate to="/" />}
            />
            <Route
              path="/create"
              element={
                <ProtectedRoute page = "create" component = {CreateBot} />
              }
            />
            <Route
              path="/manage/:id"
              element={
                <ProtectedRoute page = "manage" active = "test" component = {ManagePreview} />
              }
            />
            <Route
              path="/manage/:id/analysis"
              element={
                <ProtectedRoute page = "manage" active = "test" component = {ManageAnalysisTest} />
              }
            />
            <Route
              path="/manage/:id/share"
              element={
                <ProtectedRoute page = "manage" active = "share" component = {ManageEmbed} />
              }
            />
            <Route
              path="/manage/:id/settings"
              element={
                <ProtectedRoute page = "manage" active = "settings" component = {ManageBotWithSidebar} />
              }
            />
            <Route
              path="/manage/:id/sheet"
              element={
                <ProtectedRoute page = "manage" active = "sheet" component = {ManageSheet} />
              }
            />
            <Route
              path="/chat/:id"
              element={<Chat />}
            />
            <Route
              path="/table/:id"
              element={<Table />}
            />
            <Route
              path="/paginatedtable/:id"
              element={<PaginatedTable />}
            />
            <Route
              path="/ltd"
              element={
                <ProtectedRoute page = "ltd" component = {LTD} />
              }
            />
          </Routes>
          </QueryClientProvider>
        </div>
    </div>
  );
}

export default App;
