import { Box, Center, Spinner, Text, VStack } from '@chakra-ui/react';

const LoadingBox = () => {
  return (
    <Box height="100vh">
      <Center height="100vh">
        <VStack>
          <Spinner
            thickness="7px"
            speed="1s"
            // emptyColor='gray.200'
            color="green.600"
            size="xl"
          />
          <Text fontSize="xl" color="gray.500" ml={4}>
            Loading...
          </Text>
        </VStack>
      </Center>
    </Box>
  );
};
export default LoadingBox;
