import {
    Container,
    Flex,
    Box,
    Heading,
    Text,
    IconButton,
    Button,
    VStack,
    HStack,
    Wrap,
    WrapItem,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Textarea,
    useToast,
  } from '@chakra-ui/react';
  import { useState, useEffect } from 'react';
  import { Helmet } from 'react-helmet';

  import { useAuthContext } from '../hooks/useAuthContext';
  
const LTD = () => {

    const { user } = useAuthContext();
    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
      // fetch status of the domain from api
      fetch(`${process.env.REACT_APP_API_URL}/subscription/ltd/`, {
          method: 'GET',
          headers: {'Authorization': `Bearer ${user.data.token}`},
      })
      .then(response => {
          console.log('response', response)
          if (!response.ok) {
              throw new Error(response.statusText)
          }
          return response.json()
      }).then(data => {
          setClientSecret(data.session.client_secret)
      }).catch(error => {
          alert('Failed to fetch info.');
      })
    }, [])

    const containerStyle = {
      width: '100%', // Full width
      height: `100%I`, // Full height
      backgroundColor: '#EEF2F7', // White background
      display: 'flex', // Use flexbox to center the pricing table
      justifyContent: 'center', // Center the pricing table horizontally
      alignItems: "center",
      flexDirection: "column",
      paddingBottom: "20px",
  };

    if (!clientSecret) {
      return <div>Loading...</div>;
    }

    return (
      <div style={containerStyle}>
            <Heading mt = "10" mb = "10" >Botsheets Lifetime Deal</Heading>
            <stripe-pricing-table 
                style={{ width: '100%' }}
                pricing-table-id="prctbl_1PJedYE4JJF8I8Q1K1KwIxlp"
                publishable-key="pk_live_AvS4MVoCMFZWbZuju3jTnj2e"
                customer-session-client-secret={clientSecret}>
            </stripe-pricing-table>
        </div>
    );
  }

export default LTD;