import { Button, Card, CardBody, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast, useDisclosure, Divider} from '@chakra-ui/react'
import LoadingBox from '../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../hooks/usePublicFetch';
import { useAuthContext } from '../../hooks/useAuthContext';
import UpgradeModal from '../UpgradeModal';
import EmailNotificationModal from './data/components/EmailNotificationModal';
import { validUserPlan } from '../../auth/Permissions';

const ManageNotifications = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();
    const [upgradeText, setUpgradeText] = useState('')

    const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()
    const { isOpen: isOpenEmail, onOpen: onOpenEmail, onClose: onCloseEmail } = useDisclosure()


    const handleEmailNotificationsClick = () => {
        if (validUserPlan(2, user.data._doc.plan)) {
            onOpenEmail();
        } else {
            setUpgradeText("Upgrade plans to manage email notifications.")
            onOpen();
        }
    }
    
    if (isPending) {
    return <div>Loading Notificiation Settings...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

  return (
    <Box p = "10" pt = "5" width = "100%" height = {`calc(100vh - 5rem)`}>
        
        <Text mt = "0" fontSize="lg" fontWeight = "bold" >Email Notifications</Text>
        <Text fontSize="sm">With 10 minutes of chat inactivity your chatbot can email you any data collected and the chat transcript.</Text>
        <Flex mt = "3">
            <Button onClick={handleEmailNotificationsClick} w = "100%" borderWidth = "2px" colorScheme="green" variant = "outline">
                Manage Notifications
            </Button>
        </Flex>

        <UpgradeModal text = {upgradeText} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
        <EmailNotificationModal isOpen = {isOpenEmail} onClose = {onCloseEmail} data = {data} />
    </Box>
  )
}

export default ManageNotifications