import { Button, Card, CardBody, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast, Divider} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import DeleteBotButton from '../DeleteBotButton'
import LoadingBox from '../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../hooks/usePublicFetch';
import { useAuthContext } from '../../hooks/useAuthContext';
import Select from 'react-select'

import DataKnowledge from './data/DataKnowledge';
import DataDatapoints from './data/DataDatapoints';
import DataDocs from './data/DataDocs';
import DataRules from './data/DataRules';

const ManageData = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [selectedIndex, setSelectedIndex] = useState(0); // Default selected index
    const options = ['Collect Data', 'Share Data', 'Documents']; // Your options

    const handleTabChange = (index) => {
        setSelectedIndex(index);
    };

    console.log(data)

    const [chatbotName, setChatbotName] = useState('');
    const [selected, setSelected] = useState(options[0].value);
    const [botdata, setBotdata] = useState(data);

    const handleChange = (selectedOption) => {
        setSelected(selectedOption.value);
        console.log(`Option selected:`, selectedOption);
    };

    const handleInputChange = (e) => {
        setChatbotName(e.target.value);
    };

    const handleDeleteClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.data.token}`}
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            window.location.href = '/'

        }
        ).catch(error => {
            console.log(error)
        })
    }

    const handleRetrainClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/create/retrain/` + id, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${user.data.token}`}
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            console.log(data)
            toast({
                title: "AI Retraining",
                description: "Retraining AI now in progress. Please allow up to 60 seconds.",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch(error => {
            console.log(error)
            toast({
                title: "AI Retraining",
                description: "There was a problem and retraining AI failed.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    useEffect(() => {
        if (data) {
            setChatbotName(data.title);
            setBotdata(data);
        }
    }, [data])

    

    if (isPending) {
    return <div>Loading Chatbot Info...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

    const updateBotData = (data) => {
        setBotdata(data);
    };


  return (
    <Box p = "10" pt = "5" width = "100%">
        
        <Text fontSize="lg" fontWeight = "bold" >Train AI On Your Business Data</Text>
        <Text mb = "5" fontSize="sm">Add and delete documents and the data your chatbot collects and shares.</Text>
        <Divider />
        <Tabs variant='soft-rounded' colorScheme='green' index={selectedIndex} onChange={handleTabChange}>
        <TabList sx={{ display: 'flex', gap: '12px' }}>
        {options.map((option, index) => (
            <Tab
                key={index}
                sx={{
                    fontWeight: 'bold',
                    bg: index === selectedIndex ? 'blue.700' : 'gray.300', // Set background color based on active state
                    color: index === selectedIndex ? 'white' : 'gray.800', // Set text color based on active state
                    _selected: {
                        bg: 'blue.500', // Background color for selected (active) tab
                        color: 'white', // Text color for selected (active) tab
                    },
                    _hover: {
                        bg: index === selectedIndex ? 'blue.600' : 'gray.400', // Background color on hover
                    },
                }}
            >
                {option}
            </Tab>
        ))}
        </TabList>
        <TabPanels>
        {options.map((option, index) => (
            <TabPanel key={index}>
                {/* Render corresponding component based on the selected tab */}
                {index === 0 && <DataDatapoints data={botdata} updateBotData={updateBotData} isPending={isPending} error={error} />}
                {index === 1 && <DataKnowledge data={botdata} updateBotData={updateBotData} isPending={isPending} error={error} />}
                {index === 2 && <DataDocs data={botdata} updateBotData={updateBotData} isPending={isPending} error={error} />}
            </TabPanel>
        ))}
        </TabPanels>
        </Tabs>

        <Text mt = "10" fontSize="lg" fontWeight = "bold" >Retrain AI</Text>
        <Text fontSize="sm">If you make changes to your data, retraining AI is required.</Text>
        <Flex mt = "3">
            <Button w = "100%" borderWidth = "2px" variant = "outline" colorScheme="green" onClick = {handleRetrainClick}>
                Retrain AI
            </Button>
        </Flex>
    </Box>
  )
}

export default ManageData