import { Box, Heading, Text, VStack, Progress } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const LoadingBoxText = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 80) {
          return 80;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 80);
      });
    }, 750);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box height="80vh" pt="10vh" textAlign="center"> {/* Center align text */}
      <VStack spacing={4}>
      <Heading size='md' fontWeight = "bold" color = "#38a169">Generating Next Step</Heading>
        <Text fontSize="sm" color="gray.500">
          Do not refresh or click back in your browser...
        </Text>
        <Box width="80%" maxWidth="300px"> {/* Limit width of progress bar */}
          <Progress mt="5" value={progress} colorScheme="green" />
        </Box>
      </VStack>
    </Box>
  );
};

export default LoadingBoxText;