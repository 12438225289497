import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthContextProvider } from './context/AuthContext'
import { BotsContextProvider } from './context/BotContext';
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

const TRACKING_ID = "G-07EXF339ZM"
ReactGA.initialize(TRACKING_ID);

const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
    600: "#2a69ac",
    500: "#139f5c",
    400: "#2a69ac",
    300: "#3e74ad",
    200: "#3e74ad",
    100: "#3e74ad",
  },
  brandButton: {
    600: "#4d4d4d",
    500: "#7b7b7b",
  }
};

// themes/stepper.ts

const baseStyle = {

}

const sizes = {
}

const stepperTheme = {
  baseStyle,
  sizes,
}

const theme = extendTheme({
  components: {
    Stepper: stepperTheme,
  },
  colors
})

if (process.env.NODE_ENV !== "development")
    console.log = () => {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <AuthContextProvider>
            <BotsContextProvider>
              <App />
            </BotsContextProvider>
        </AuthContextProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);