import { Heading, Text, Spinner, Flex, Box} from '@chakra-ui/react'
import DeleteBotButton from './DeleteBotButton'
import { useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import LoadingBox from './common/LoadingBox'
import './Manage.css';
import ManageTest from './ManageTest'
import ManageAnalysisTest from './ManageAnalysisTest'
import Confetti from 'react-confetti'
import { usePublicFetch } from '../hooks/usePublicFetch'

const ManagePreview = (props) => {

  const {id} = useParams();
  const error = props.error;
  const isPending = props.isPending;
  const [loaded, setLoaded] = useState(false);
  const { data, fetchError, fetchIsPending } = usePublicFetch(`${process.env.REACT_APP_API_URL}/bot/` + id);

  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');
  


  if (isPending || fetchIsPending) {
    return <div>Loading Chatbot Info...</div>
  }

  if (error || fetchError) {
    return <div>{ error }</div>
  }

    //create an onload function for the iframe that console.log
  
    const handleLoad = () => {
      //console.log('iframe loaded')
      setLoaded(true);
    }

    const currentDomain = window.location.origin;
    console.log(currentDomain)

  return (
    <div style={{ width: "100%", height: `calc(100vh - 5rem)`}}>
      {source == "create" && <Confetti recycle={false}/>}
      {data && data.type === "analysis" ? <ManageAnalysisTest /> : <ManageTest />}
    </div>
  )
}

export default ManagePreview