import React, { useState } from 'react';
import { Box, Button, Modal, ModalOverlay, Select, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, FormControl, FormLabel, Input, Link } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';

const CreateAnalaysisModal = ({ isOpen, onClose }) => {
  // State to hold the input value
  const [googleSheetsURL, setGoogleSheetsURL] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const sourceToURLMapping = {
    "apparelSalesReport": "https://docs.google.com/spreadsheets/d/1zNQUIjSH-fT1pp6NBc0A0hZjEkupuEGLRiN1y9alRBU/edit#gid=0",
    "amazonSalesReport": "https://docs.google.com/spreadsheets/d/1mLIcHP-twMAYnTXb6rYFf7o2Vhm2PxJnT7BRVClXacM/edit#gid=0",
    "collegeData": "https://docs.google.com/spreadsheets/d/1ZZcXyMDV3rN9H2bzo4-9P0ZRGNeE-h02SwV7lCz3DXo/edit#gid=574535183",
    "supermarketSales": "https://docs.google.com/spreadsheets/d/1gOsH6b2FtWJ6nZPeNhJae-bKrWf5mln0EVCyazklGeM/edit#gid=349510887",
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSelectedSource(value)

    // set google sheets url to the sourceToURLMapping if it exists and is not empty
    setGoogleSheetsURL(sourceToURLMapping[value] || '');
};


  const handleBotCreation = () => {

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/create/data`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
        body: JSON.stringify({
          url: googleSheetsURL,
        }),
    }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
            return response.json().then(errorData => {
              throw new Error(JSON.stringify(errorData));
            });
        }
    }).then((data) => {
        window.location.href = `/manage/${data.bot_id}?source=create`;
        setLoading(false);
    })
    .catch((error) => {
        // get the error from the stringified JSON
        error = JSON.parse(error.message);
        const errorMessage = error.error ? error.error : "Failed to create bot. Please try again.";
        alert(errorMessage);
        setLoading(false);
    });
  }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a Data Analysis Chatbot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <Text mb = "3" >Get actionable insights with charts and graphs on <Link href="https://support.botsheets.com/docs/datasets" isExternal color='#1772cc'>datasets</Link> stored in Google Sheets.</Text>
            <Text mb = "3">Start with a template if you don't have a dataset prepared. We will clone the template to your Google Drive.</Text>
            <Select
            name="leadingQuestion"
            backgroundColor = "gray.100"
            value={selectedSource}
            onChange={handleInputChange}
            borderColor = "gray.300"
            placeholder="Select a Dataset">
              <option value="amazonSalesReport">Amazon Sales Report</option>
              <option value="collegeData">College Data</option>
              <option value="apparelSalesReport">Apparel Sales Report</option>
              <option value="supermarketSales">Supermarket Sales</option>
              <option value="customSheet">Add Your Own</option>
            </Select>

            {sourceToURLMapping[selectedSource] && <>
            <Link href = {sourceToURLMapping[selectedSource]} mt={2} fontSize="sm" color="green.600" isExternal>
              Preview selected dataset
            </Link></>}

            {selectedSource === 'customSheet' && <><FormLabel htmlFor="google-sheets-url">&nbsp;</FormLabel>
            <Input 
              id="google-sheets-url"
              type="text"
              backgroundColor = "gray.100"
              borderColor = "gray.300"
              value={googleSheetsURL}
              onChange={(e) => setGoogleSheetsURL(e.target.value)}
              placeholder="Paste in the URL of a Google Sheet"
            />
            <Text mt={2} fontSize="sm" color="gray.600">
            Botsheets reads only the default worksheet tab. Connect your spreadsheet once and we keep AI in sync with changes to your data. If your Google Sheet is owned by a different Google Account, you will need to <Link href="https://support.botsheets.com/docs/connected-data" isExternal color='#1772cc'>change sharing permission</Link> on the Google Sheet so Botsheets can read it.
            </Text></>}
            
            <Text mt={2} fontSize="sm" color="gray.600">
            Botsheets requires datasets. Ensure your data is properly <Link href="https://support.botsheets.com/docs/datasets" isExternal color='#1772cc'>formatted</Link>.
            </Text>

            
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} colorScheme = "green" onClick={handleBotCreation}>
            Create Bot
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateAnalaysisModal;
