import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  Text,
  ModalFooter,
  Code,
  FormLabel,
  VStack,
  Switch,
  useToast,
  useDisclosure

} from '@chakra-ui/react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useParams } from 'react-router-dom';
import { validUserPlan } from '../../../../auth/Permissions';
import UpgradeModal from '../../../UpgradeModal';

const SwitchGroup = ({ name, label, explanation, isChecked, settings, setSettings, reqPlan, user, upgradeText }) => {
  const [checked, setChecked] = useState(isChecked);
  const { isOpen, onOpen, onClose: onCloseModal } = useDisclosure()
  
  const handleToggle = (reqPlan) => {

    if (validUserPlan(reqPlan, user.data._doc.plan)) {
      setChecked(!checked);
      setSettings({...settings, [name]: !checked})
    } else {
      onOpen();
    }
  };

  return (
    <Box>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Text fontSize="sm">{explanation}</Text>
      <Switch id={name} isChecked={checked} onChange={() => handleToggle(reqPlan)} />
      <UpgradeModal text = {upgradeText} user = {user} userPlan = {user.data._doc.plan} isOpen = {isOpen} onClose = {onCloseModal}/>
    </Box>
  );
  
};


const EmailNotificationModal = ({ isOpen, onClose, data }) => {
  const { user } = useAuthContext();
  const {id} = useParams();
  const [settings, setSettings] = useState({})
  const [email, setEmail] = useState('')
  const [secondaryEmail, setSecondaryEmail] = useState('')
  const toast = useToast();

  useEffect(() => {
    if (data) {
        setSettings(data.notificationEmailRules);
        setEmail(data.notificationEmail);
        setSecondaryEmail(data.secondaryNotificationEmail);
    }
  }, [data])

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSaveClick = () => {

    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
      method: 'PATCH',
      headers: {'Authorization': `Bearer ${user.data.token}`, 'Content-Type': 'application/json'},
      body: JSON.stringify({'notificationEmail': email, 'secondaryNotificationEmail': secondaryEmail, 'notificationEmailRules': settings})
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(response.status)
        }
        return response.json()
    }).then(data => {
        toast({
            title: `Successfully Saved. Refresh page to see changes.`,
            isClosable: true,
            status: "success",
            duration: 1800,
          })
        setLoading(false);
        onClose();
    }
    ).catch(error => {
        setLoading(false);
        console.log(error)
    })

  };


  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Email Notification Settings</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormControl>
          <FormLabel color="#000000" fontSize="lg" fontWeight="semibold">
            Recipients
          </FormLabel>
          <Text fontSize="sm" mb="3">
            After each chat session we send an email notification. Include up to 2 emails. Leave blank to disable email notifications.
          </Text>
          <Input
            placeholder="your-email@example.com"
            type="email"
            value={email}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          />
          <Input
            mt  = "4"
            placeholder="your-email@example.com"
            type="email"
            value={secondaryEmail}
            onChange={(e) => setSecondaryEmail(e.target.value)}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          />

          <VStack align="start" mt = "4" spacing={4}>
            {/*             <SwitchGroup 
              name="summary" 
              label="Email Summary" 
              explanation="Include a summary of the chatbot interaction in notifications." 
              isChecked={settings.summary}
              settings={settings}
              setSettings={setSettings}
              reqPlan = {0}
              user = {user}
            /> */}
            <SwitchGroup
              name="datapoints" 
              label="Data Points" 
              explanation="Include key data extracted and collected in a chat session." 
              isChecked={settings.datapoints}
              settings={settings}
              setSettings={setSettings}
              reqPlan = {0}
              user = {user}
              upgradeText = "Upgrade to include key data points collected by a chatbot in email notifications."
            />
            <SwitchGroup
              name="transcript" 
              label="Chat Transcript" 
              explanation="Include the complete chat transcript." 
              isChecked={settings.transcript}
              settings={settings}
              setSettings={setSettings}
              reqPlan = {0}
              user = {user}
              upgradeText = "Upgrade your plan to include chat transcripts in email notifications."
            />
            <SwitchGroup
              name="dashboardLink" 
              label="Dashboard Link" 
              explanation="Include a link to the Botsheets Dashboard." 
              isChecked={settings.dashboardLink} 
              settings={settings}
              setSettings={setSettings}
              reqPlan = {0}
              user = {user}
            />
            <SwitchGroup
              name="sheetLink" 
              label="Google Sheet Link" 
              explanation="Include a link to the Google Sheet capturing key data." 
              isChecked={settings.sheetLink}
              settings={settings}
              setSettings={setSettings}
              reqPlan = {0}
              user = {user}
            />
            <SwitchGroup
              name="logo" 
              label="Botsheets Branding" 
              explanation="Include the Botsheets logo in email notifications." 
              isChecked={settings.logo}
              settings={settings}
              setSettings={setSettings}
              reqPlan = {2}
              user = {user}
              upgradeText = "Upgrade your plan to remove Botsheets branding from email notifications."
            />
          </VStack>
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <Button isLoading={loading} colorScheme="green" onClick={handleSaveClick}>
          Save Changes
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
  

  );
};

export default EmailNotificationModal;