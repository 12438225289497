import { Button, Card, CardBody, CardFooter, Input, CardHeader, Heading, SimpleGrid, Text, Stack, StackDivider, Box, Code, Flex, useToast, Divider} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import DeleteBotButton from '../DeleteBotButton'
import LoadingBox from '../common/LoadingBox';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { usePublicFetch } from '../../hooks/usePublicFetch';
import { useAuthContext } from '../../hooks/useAuthContext';
import Select from 'react-select'

import DataPrompt from './data/DataPrompt';
import DataRules from './data/DataRules';

const ManageRules = (props) => {

    const data = props.data;
    const error = props.error;
    const isPending = props.isPending;

    const { id } = useParams();
    const { user } = useAuthContext();
    const toast = useToast();

    const [selectedIndex, setSelectedIndex] = useState(0); // Default selected index
    var options = ['Basic Rules', 'Prompt Editor']; // Your options

    if (data.type == 'analysis') {
        options = ['Basic Rules'];
    }

    const handleTabChange = (index) => {
        setSelectedIndex(index);
    };

    console.log(data)

    const [chatbotName, setChatbotName] = useState('');
    const [selected, setSelected] = useState(options[0].value);
    const [botdata, setBotdata] = useState(data);

    const handleChange = (selectedOption) => {
        setSelected(selectedOption.value);
        console.log(`Option selected:`, selectedOption);
    };

    const handleInputChange = (e) => {
        setChatbotName(e.target.value);
    };

    const handleDeleteClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/bot/` + id, {
            method: 'DELETE',
            headers: {'Authorization': `Bearer ${user.data.token}`}
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            window.location.href = '/'

        }
        ).catch(error => {
            console.log(error)
        })
    }

    const handleRetrainClick = () => {
        fetch(`${process.env.REACT_APP_API_URL}/create/retrain/` + id, {
            method: 'POST',
            headers: {'Authorization': `Bearer ${user.data.token}`}
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
            console.log(data)
            toast({
                title: "AI Retraining",
                description: "Retraining AI now in progress. Please allow up to 60 seconds.",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
        }).catch(error => {
            console.log(error)
            toast({
                title: "AI Retraining",
                description: "There was a problem and retraining AI failed.",
                status: "error",
                duration: 5000,
                isClosable: true,
            })
        })
    }

    useEffect(() => {
        if (data) {
            setChatbotName(data.title);
            setBotdata(data);
        }
    }, [data])

    

    if (isPending) {
    return <div>Loading Rules...</div>
    }

    if (error) {
    return <div>{ error }</div>
    }

    const updateBotData = (data) => {
        setBotdata(data);
    };


  return (
    <Box p = "10" pt = "5" width = "100%">
        
        <Text fontSize="lg" fontWeight = "bold" >Rules of Engagement</Text>
        <Text mb = "5" fontSize="sm">Set basic rules to guide your chatbot, or more advanced rules with our Prompt Editor.</Text>
        <Divider />
        <Tabs variant='soft-rounded' colorScheme='green' index={selectedIndex} onChange={handleTabChange}>
            {data.type != "analysis" && (
            <TabList sx={{ display: 'flex', gap: '12px' }}>
                {options.map((option, index) => (
                    <Tab
                        key={index}
                        sx={{
                            fontWeight: 'bold',
                            bg: index === selectedIndex ? 'blue.700' : 'gray.300', // Set background color based on active state
                            color: index === selectedIndex ? 'white' : 'gray.800', // Set text color based on active state
                            padding: '8px 16px', // Padding inside each tab
                            _selected: {
                                bg: 'blue.500', // Background color for selected (active) tab
                                color: 'white', // Text color for selected (active) tab
                            },
                            _hover: {
                                bg: index === selectedIndex ? 'blue.600' : 'gray.400', // Background color on hover
                            },
                        }}
                    >
                        {option}
                    </Tab>
                ))}
            </TabList>)}

            <TabPanels>
                {options.map((option, index) => (
                    <TabPanel key={index}>
                        {/* Render corresponding component based on the selected tab */}
                        {index === 0 && <DataRules data={botdata} updateBotData={updateBotData} isPending={isPending} error={error} />}
                        {index === 1 && <DataPrompt data={botdata} updateBotData={updateBotData} isPending={isPending} error={error} />}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>
        <Text mt = "10" fontSize="lg" fontWeight = "bold" >Retrain AI</Text>
        <Text fontSize="sm">If you make changes to your data, retraining AI is required.</Text>
        <Flex mt = "3">
            <Button w = "100%" borderWidth = "2px" variant = "outline" colorScheme="green" onClick = {handleRetrainClick}>
                Retrain AI
            </Button>
        </Flex>
    </Box>
  )
}

export default ManageRules