import { useState, useEffect } from 'react';
import { Box, Button, Text, UnorderedList, ListItem, useDisclosure, Badge, Heading, Link, TableContainer, Table, TableCaption, HStack, Spacer, Thead, Tr, Th, Tfoot, Td, Tbody } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import LoadingBox from '../common/LoadingBox';
import PDFImportModal from './components/PDFImportModal';
import URLImportModal from './components/URLImportModal';
import AddInfoModal from './components/AddInfoModal';
import AddSheetsModal from './components/AddSheetsModal';
import { parse } from 'date-fns';

const Step2 = ({goToNext, goToPrevious, setActiveStep}) => {

    const { isOpen: isPDFOpen, onOpen: onPDFOpen, onClose: onPDFClose } = useDisclosure()
    const { isOpen: isURLOpen, onOpen: onURLOpen, onClose: onURLClose } = useDisclosure()
    const { isOpen: isSheetsOpen, onOpen: onSheetsOpen, onClose: onSheetsClose } = useDisclosure()
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

    const [storedBot, setStoredBot] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalCharCount, setTotalCharCount] = useState(0);

    const [datasources, setDatasources] = useState([]);
    const [selectedDatapoints, setSelectedDatapoints] = useState([]);
    const [sheetsURL, setSheetsURL] = useState([]);

    useEffect(() => {
        //set total char count to the sum of all char counts in datasources
        let total = 0;
        datasources.forEach((datasource) => {
            if (!isNaN(datasource.charCount)) {
                total += datasource.charCount;
            }
        })
        setTotalCharCount(total);
    }, [datasources])

    const {user} = useAuthContext();

    console.log(sheetsURL)

    useEffect(() => {
        // Retrieve the object from sessionStorage
        const storedData = sessionStorage.getItem('storedBot');
        const step2 = sessionStorage.getItem('step2');
        const storedSelectedData = sessionStorage.getItem('step3_selected');
        const GSheetsURL = sessionStorage.getItem('GSheetsURL');
    
        if (storedData) {
          // If data exists in sessionStorage, parse it back to an object
          const parsedObject = JSON.parse(storedData);
          setStoredBot(parsedObject);

          if (step2) {
            // If data exists in sessionStorage, parse it back to an object
            const parsedObject = JSON.parse(step2);
            setDatasources(parsedObject);
          }

          if (storedSelectedData) {
            const parsedSelectedData = JSON.parse(storedSelectedData);
            setSelectedDatapoints(parsedSelectedData);
          }

        if (GSheetsURL) {
            const parsedGSheetsURL = JSON.parse(GSheetsURL);
            setSheetsURL(parsedGSheetsURL);
        }

          setLoading(false);
        }
      }, []);

    const addNewDatapoint = (name, description) => {
        setSelectedDatapoints([...selectedDatapoints, { data_point: name, userInput: description, description: '' }]);
    }

    const handleInputChange = (datapoint, inputValue) => {
        // Create a copy of the selectedDatapoints array
        const updatedSelectedDatapoints = [...selectedDatapoints];

        // Find the index of the datapoint in the array
        const index = updatedSelectedDatapoints.findIndex(item => item.data_point === datapoint.data_point);

        if (inputValue.trim() === '') {
            // If input is empty, remove the datapoint from the array
            if (index !== -1) {
                updatedSelectedDatapoints.splice(index, 1);
            }
        } else {
            // If input is not empty, update or add the datapoint in the array
            if (index !== -1) {
                updatedSelectedDatapoints[index] = { ...datapoint, userInput: inputValue };
            } else {
                updatedSelectedDatapoints.push({ ...datapoint, userInput: inputValue });
            }
        }

        // Update the state with the modified array
        setSelectedDatapoints(updatedSelectedDatapoints);
    };

    // Function to add a new data source with a "pending" status
    const addDataSource = (obj) => {
        setDatasources([...datasources, obj]);
    };

    const addSheetsURLSource = (url, desc) => {

        // if sheetsURL contains more than 5 items, alert the user and return
        if (sheetsURL.length >= 5) {
            alert('You can only connect up to 5 Google Sheets during setup. Add more in Bot Settings.');
            return;
        }

        // Create a new array with the updated state
        const updatedSheetsURL = [...sheetsURL, { url, desc }];
        
        // Update the state with the new array
        setSheetsURL(updatedSheetsURL);
    }

    // Function to update the status of a data source to "done"
    const markDataSourceAsDone = (name, statusMessage = 'Processed', charCount = "Unknown") => {
        setDatasources((prevDataSources) =>
        prevDataSources.map((dataSource) =>
            dataSource.name === name ? { ...dataSource, status: statusMessage, charCount: charCount } : dataSource
        )
        );
    };




    const handleContinueClick = () => {
        if (!datasources) {
          alert('Try adding at least one data source.');
          return;
        }

        if ((user.data._doc.charCount + totalCharCount) > user.data.limits.charCount) {
            alert('You have exceeded your character limit. Delete some data sources or upgrade your plan.');
            return;
        }

        const allDone = datasources.every((datasource) => datasource.status === 'Processed' || datasource.status === 'Failed');
        if (!allDone) {
            alert('Wait until all datasources have processed.');
            return;
        }

        const updatedStoredBot = {
            ...storedBot,
            question_answers: selectedDatapoints,
            GSheets: sheetsURL
          };

        setStoredBot(updatedStoredBot);

        sessionStorage.setItem('GSheetsURL', JSON.stringify(sheetsURL));
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));
        sessionStorage.setItem('step3_selected', JSON.stringify(selectedDatapoints));
        sessionStorage.setItem('step2', JSON.stringify(datasources));

        goToNext();
    
      };
      

    const handlePreviousClick = () => {

        const allDone = datasources.every((datasource) => datasource.status === 'Processed' || datasource.status === 'Failed');
        if (!allDone) {
            alert('Wait until all datasources have processed.');
            return;
        }

        const updatedStoredBot = {
            ...storedBot,
            question_answers: selectedDatapoints,
            GSheets: sheetsURL
          };

        setStoredBot(updatedStoredBot);

        sessionStorage.setItem('GSheetsURL', JSON.stringify(sheetsURL));
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));
        sessionStorage.setItem('step3_selected', JSON.stringify(selectedDatapoints));
        sessionStorage.setItem('step2', JSON.stringify(datasources));
        
        goToPrevious();
    };

    const handleDelete = (datasource) => {
        setDatasources(datasources.filter((item) => item.name !== datasource.name));

        // Store predicted category in the storedBot object
        const updatedStoredBot = {
            ...storedBot,
            documents: storedBot.documents.filter((item) => item.name !== datasource.name)
        };

        setStoredBot(updatedStoredBot);
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));
    }

    const handleDeleteDatapoint = (datapoint) => {
        setSelectedDatapoints(selectedDatapoints.filter((item) => item.data_point !== datapoint.data_point));

        const updatedStoredBot = {
            ...storedBot,
            question_answers: storedBot.question_answers.filter((item) => item.data_point !== datapoint.data_point)
        };

        setStoredBot(updatedStoredBot);
        sessionStorage.setItem('storedBot', JSON.stringify(updatedStoredBot));
    }

    const handleDeleteSheet = (sheet) => {
        setSheetsURL(sheetsURL.filter((item) => item.url !== sheet.url));
    }

    if (loading) {
        return <LoadingBox />;
    }

    return (
        <Box>
            <Box mb = "10" display = 'flex' flexDir = "column">
                <Text color = "#EC6060" fontSize = "xl" fontWeight = "semibold" >Train AI to respond with your business data</Text>
                <Text mb = "5" color = "gray.500" fontSize="md">
                <UnorderedList>
                    <ListItem>Input key business data such as your business name or hours of operation.</ListItem>
                    <ListItem>Upload PDFs and <Link href="https://support.botsheets.com/docs/connected-data" isExternal color='#1772cc'>connect</Link> webpages. (You have {user.data._doc.charCount + totalCharCount} of {user.data.limits.charCount} document characters available) </ListItem>
                    <ListItem><Link href="https://support.botsheets.com/docs/connected-data" isExternal color='#1772cc'>Connect</Link> datasets in Google Sheets. See <Link href="https://support.botsheets.com/docs/datasets" isExternal color='#1772cc'>how to prepare your data</Link> and <Link href="https://support.botsheets.com/docs/datasets" isExternal color='#1772cc'>access templates</Link>.</ListItem>
                    <ListItem>For connected data sources, your chatbot will automatically keep AI in sync with changes to your data.</ListItem>
                </UnorderedList>
                </Text>
                
                <Box mt = "2" >
                    <Button onClick={onModalOpen} mr = "3" colorScheme = "blue" >Input Key Data</Button>
                    <Button onClick={onSheetsOpen} mr = "3" colorScheme = "blue" >Connect Google Sheet</Button>
                    <Button onClick={onURLOpen} mr = "3" colorScheme = "blue" >Connect Webpage</Button>
                    <Button onClick={onPDFOpen} mr = "3" colorScheme = "blue" >Upload PDF</Button>
                </Box>

                <TableContainer mt = "5" >
                <Table variant='simple'>
                    <Thead>
                    <Tr>
                        <Th>Datasource</Th>
                        <Th>Data Type</Th>
                        <Th>Status</Th>
                        <Th>Character Count</Th>
                        <Th>Actions</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {datasources.map((datasource, index) => (
                    <Tr key = {index}>
                        <Td>{datasource.name}</Td>
                        <Td>{datasource.type}</Td>
                        <Td><Badge variant='outline' colorScheme={
                        (() => {
                            switch (datasource.status) {
                            case 'Failed':
                                return 'red';
                            case 'Processing':
                                return 'yellow';
                            default:
                                return 'green';
                            }
                        })()
                        }>{datasource.status}</Badge></Td>
                        <Td>{datasource.charCount}</Td>
                        <Td><Button onClick = {() => handleDelete(datasource)} colorScheme = "red">Delete</Button></Td>
                    </Tr>
                    ))}
                    {selectedDatapoints.map((datapoint, index) => (
                    <Tr key = {index}>
                        <Td>{datapoint.data_point}</Td>
                        <Td>User Input</Td>
                        <Td><Badge variant='outline' colorScheme='green'>Processed</Badge></Td>
                        <Td>N/A</Td>
                        <Td><Button onClick = {() => handleDeleteDatapoint(datapoint)} colorScheme = "red">Delete</Button></Td>
                    </Tr>
                    ))}
                    {sheetsURL.map((sheet, index) => (
                        <Tr key={index}>
                            <Td><a href={sheet.url} target="_blank"><Text color = "#1772cc" fontSize="md">{sheet.desc}</Text></a></Td>
                            <Td>Google Sheets</Td>
                            <Td><Badge variant='outline' colorScheme='green'>Processed</Badge></Td>
                            <Td>N/A</Td>
                            <Td><Button onClick={() => handleDeleteSheet(sheet)} colorScheme="red">Delete</Button></Td>
                        </Tr>
                    ))}
                    </Tbody>
                </Table>
                </TableContainer>

                

                <AddSheetsModal addSheetsURLSource = {addSheetsURLSource} markDataSourceAsDone = {markDataSourceAsDone} addDataSource = {addDataSource} storedBot = {storedBot} setStoredBot = {setStoredBot} isOpen={isSheetsOpen} onClose={onSheetsClose} />
                <PDFImportModal markDataSourceAsDone = {markDataSourceAsDone} addDataSource = {addDataSource} storedBot = {storedBot} setStoredBot = {setStoredBot} isOpen={isPDFOpen} onClose={onPDFClose} />
                <URLImportModal markDataSourceAsDone = {markDataSourceAsDone} addDataSource = {addDataSource} storedBot = {storedBot} setStoredBot = {setStoredBot} isOpen={isURLOpen} onClose={onURLClose} />
                <AddInfoModal isOpen={isModalOpen} onClose={onModalClose} addNewDatapoint={addNewDatapoint} />
                

            </Box>

            <HStack>
                <Button mr = "3" colorScheme = "green" onClick={handlePreviousClick}>Previous</Button>
                <Spacer />
                <Button colorScheme = "green" onClick={handleContinueClick}>Continue</Button>
            </HStack>
            
        </Box>
    )
}

export default Step2