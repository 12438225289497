import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import CreateAnalaysisModal from './CreateAnalysisModal';

const CreateAnalysisButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button w="100%" mt="3" colorScheme="green" onClick={onOpen}>
        Create Chatbot
      </Button>

      <CreateAnalaysisModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default CreateAnalysisButton;
