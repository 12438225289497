import { useState, useEffect } from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Select,
    Checkbox,
    Textarea,
    Button,
    HStack,
    Spacer,
    Text,
    useDisclosure
  } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';
import LoadingBox from '../common/LoadingBox';
import { useNavigate, Link } from 'react-router-dom';
import CreatingBotModal from './components/CreatingBotModal';
import ConfettiExplosion from 'react-confetti-explosion';
import { parse } from 'date-fns';


const Step6 = ({goToNext, goToPrevious}) => {

    const [storedBot, setStoredBot] = useState(null);
    const { user } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [isExploding, setIsExploding] = useState(false);

    const [formData, setFormData] = useState({
        chatbotName: 'My Chatbot' + Math.floor(Math.random() * 1000),
        welcomeMessage: 'Hi, I’m an AI assistant ready to help you.',
        leadingQuestion: 'No',
        enforceEmailCollection: false,
        emailCollectionReason: '',
        enforceDataValidation: false,
        enforceDataTypes: [],
        crossReferenceData: false,
        tone: 'Witty',
        customWelcomeMessage: '',
      });

    const [leadingChoices, setLeadingChoices] = useState([]);

    useEffect(() => {
        // Retrieve the object from sessionStorage
        const storedData = sessionStorage.getItem('storedBot');
        const step5 = sessionStorage.getItem('step5');
        const step4 = sessionStorage.getItem('step4_selected');
        const GSheetsURL = sessionStorage.getItem('GSheetsURL');

        if (storedData) {
          
          // If data exists in sessionStorage, parse it back to an object
          const parsedObject = JSON.parse(storedData);
          console.log('storedData', parsedObject)
          setStoredBot(parsedObject);
          var newFormData = {}

          if (step5) {
            const parsedObject = JSON.parse(step5);
            newFormData = parsedObject;
            setFormData(parsedObject);

            console.log('step5', parsedObject)
            console.log(formData)
          }

          if (step4) {
            const parsedObject = JSON.parse(step4);
            setLeadingChoices(parsedObject);
          }

          setLoading(false);
        }
    }, []);

    console.log('formData', formData)

    const handleDoneClick = () => {
        setButtonLoading(true);
        setLoading(true);


        if (!formData) {
          alert('Error saving your data. Please try again.');
          setButtonLoading(false);
          return;
        }

        const updatedBot = {
            ...storedBot,
            rules: formData,
        }

        sessionStorage.setItem('storedBot', JSON.stringify(updatedBot));
        setStoredBot(updatedBot);

        // validate form data
        if (!formData.chatbotName || !formData.welcomeMessage) {
            alert('Please fill out all fields');
            setButtonLoading(false);
            setLoading(false);
            return;
        }

        if (formData.leadingQuestion === 'Yes' && !formData.additionalWelcomeMessage) {
            alert('You indicated that you want to add a leading question, but you did not provide one. Add a leading question or change your preference.');
            setButtonLoading(false);
            setLoading(false);
            return;
        }

        if (formData.additionalWelcomeMessage === "CUSTOM") {
          formData.additionalWelcomeMessage = formData.customWelcomeMessage;
        }
        

        fetch(`${process.env.REACT_APP_API_URL}/create`, {
          method: 'POST',
          headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${user.data.token}`},
          body: JSON.stringify(updatedBot)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.status)
            }
            return response.json()
        }).then(data => {
          console.log('valid')

          console.log(data.bot_id);

          setButtonLoading(false);
          sessionStorage.removeItem('storedBot');
          sessionStorage.removeItem('step2');
          sessionStorage.removeItem('step3_all');
          sessionStorage.removeItem('step3_selected');
          sessionStorage.removeItem('step4_all');
          sessionStorage.removeItem('step4_selected');
          sessionStorage.removeItem('step5');
          sessionStorage.removeItem('GSheetsURL');

          window.location.href = `/manage/${data.bot_id}?source=create`;

        }).catch(error => {
          console.log(error);
          setLoading(false);
          alert('An error occured, please try again. Check your account limits and upgrade if necessary.');
        })
    
      };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handlePreviousClick = () => {

      sessionStorage.setItem('step5', JSON.stringify(formData));

      goToPrevious();

    };

    const handleContinueClick = () => {
      sessionStorage.setItem('step5', JSON.stringify(formData));
      
      goToNext();
    }


    if (loading) {
        return <LoadingBox />;
    }

    return (
        <Box>
            <Box display = 'flex' flexDir = "column">

                <form>

      <Box mb={4}>
        <FormControl>
          <FormLabel>
          <Text color = "#EC6060" fontSize = "xl" fontWeight = "semibold">Give your chatbot a name</Text>
                <Text mb = "5" color = "gray.500" fontSize="md">This is a public facing name that will appear in the header of your chatbot. Click done to start testing your bot. You can edit all of the information you have provided after in your Bot Settings.</Text>
          </FormLabel>
          <Input
            name="chatbotName"
            value={formData.chatbotName}
            onChange={handleInputChange}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
          />
        </FormControl>
      </Box>

    </form>

            </Box>

            <HStack mt = "5">
                <Button mr = "3" colorScheme = "green" onClick={handlePreviousClick}>Previous</Button>
                <Spacer />
                <Box>
                {isExploding && <ConfettiExplosion duration="3000"/>}
                <Button colorScheme = "green" onClick={handleDoneClick}>Done</Button>
                </Box>
                
            </HStack>
            
           {/* <pre>{JSON.stringify(storedBot)}</pre> */}
           
        </Box>
        
    )
}

export default Step6