import React, { useState } from 'react';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, FormControl, FormLabel, Input } from '@chakra-ui/react';

const AddInfoModal = ({ isOpen, onClose, addNewDatapoint }) => {

    const [datapointName, setDatapointName] = useState('');
    const [description, setDescription] = useState('');

    const handleNewDatapoint = () => {
        if (!datapointName || !description) {
            alert('Please fill out all fields.');
            return;
        }

        addNewDatapoint(datapointName, description);

        setDatapointName('');
        setDescription('');

        onClose();
    }

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Key Business Details</ModalHeader>
        <Text mb = "5" p = "6" fontSize="md" color="gray.600">Share key facts about your business. This is a simple data structure that is easy for AI to understand.</Text>
        <ModalCloseButton />
        <ModalBody>
          <Box>
          <FormControl isRequired>
            <FormLabel>Data Point</FormLabel>
            <Input 
            placeholder='e.g. Hours of Operation'
            value={datapointName}
            onChange={(e) => setDatapointName(e.target.value)}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
            />
            
            <FormLabel mt = "3" >Data Value</FormLabel>
            <Input 
            placeholder='e.g. 9-5 Monday to Friday '
            value={description}
            backgroundColor = "gray.100"
            borderColor = "gray.300"
            onChange={(e) => setDescription(e.target.value)} />
            
          </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick = {handleNewDatapoint} colorScheme = "green">Add Key Data</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddInfoModal;